<template>
  <div class="box admin-toolbox">
    <p>You're an <b>Admin</b>. Here are some tools for you:</p>
    <ul>
      <li>
        <router-link class="ïs-danger" :to="{ name: 'ApproveEvents' }">
          Approve Events
        </router-link>
        (<span class="is-danger">{{ pendingCount }}</span
        >)
      </li>
      <li>
        <router-link class="is-danger" :to="{ name: 'ApproveChanges' }">
          Approve Event Changes
        </router-link>
        (<span class="is-danger">{{ pendingUpdateCount }}</span
        >)
      </li>
      <li>
        <router-link class="is-danger" :to="{ name: 'EventDashboard' }">
          View Event Dashboard
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import db from "@/firebase/firestore.js";
export default {
  name: "AdminToolBox",
  data() {
    return {
      pendingCount: 0,
      pendingUpdateCount: 0
    };
  },
  created() {
    var close = db.collection("pending_events").onSnapshot(ss => {
      this.pendingCount = ss.size;
    });
    this.$store.dispatch("addCleanup", close);
    close = db.collection("pending_event_updates").onSnapshot(ss => {
      this.pendingUpdateCount = ss.size;
    });
    this.$store.dispatch("addCleanup", close);
  }
};
</script>

<style scoped>
.admin-toolbox li {
  list-style: none;
}
.box.admin-toolbox {
  margin-right: 25px;
}
</style>
