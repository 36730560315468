// Vuex module store related to user data
import Vue from "vue";
import db from "../../firebase/firestore";

let userStore = {
  state: {
    user: localStorage.user ? JSON.parse(localStorage.user) : null,
    cleanupFunctions: []
  },
  mutations: {
    setUser(state, user) {
      Vue.set(state, "user", user);
    },
    logout(state) {
      state.cleanupFunctions.forEach(fn => fn());
      state.cleanupFunctions = [];
      Vue.set(state, "user", null);
    },
    addCleanup(state, fn) {
      state.cleanupFunctions.push(fn);
    }
  },
  actions: {
    setUser(context, user) {
      context.commit("setUser", user);
      let cleanup = db
        .collection("user_interest_log")
        .doc(user.id)
        .collection("events")
        .onSnapshot(ss => {
          context.commit("bookmarksLoaded", true, { root: true });
          ss.docChanges().forEach(ch => {
            switch (ch.type) {
              case "added":
                context.commit(
                  "addInterest",
                  {
                    id: ch.doc.id,
                    data: ch.doc.data()
                  },
                  { root: true }
                );
                break;
              case "removed":
                context.commit("removeInterest", ch.doc.id, { root: true });
                break;
              default:
                console.error("Unexpected change type: ", ch.type);
            }
          });
        });
      context.commit("addCleanup", cleanup);
    },
    addCleanup(context, fn) {
      context.commit("addCleanup", fn);
    },
    logout(context) {
      context.commit("logout");
      context.commit("clearBookmarkState", null, { root: true });
    }
  },
  getters: {
    user: state => state.user
  }
};

export default userStore;
