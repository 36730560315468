// Store functions related to managing a user's bookmarks
import Vue from "vue";
import moment from "moment-timezone";

let bookmarks = {
  state: {
    starredEvents: {},
    starredEventList: [],
    bookmarksLoaded: false
  },
  mutations: {
    addInterest(state, event) {
      if (!state.starredEvents[event.id]) {
        Vue.set(state.starredEvents, event.id, event);
        state.starredEventList.push(event);
      }
    },
    removeInterest(state, eventId) {
      if (state.starredEvents[eventId]) {
        Vue.set(state.starredEvents, eventId, null);
        let filteredList = state.starredEventList.filter(
          obj => obj.id !== eventId
        );
        Vue.set(state, "starredEventList", filteredList);
      }
    },
    bookmarksLoaded(state, val) {
      state.bookmarksLoaded = val;
    },
    clearBookmarkState(state) {
      Vue.set(state, "starredEvents", {});
      Vue.set(state, "starredEventList", []);
      state.bookmarksLoaded = false;
    }
  },
  getters: {
    bookmarksLoaded: function(state) {
      return state.bookmarksLoaded;
    },
    // returns a list of bookmarked events that will be happening in the next
    // seven days.
    upcomingStarredEvents: function(state) {
      let now = moment()
        .utc()
        .format();
      let endDate = moment()
        .utc()
        .add(7, "days")
        .format();
      let nextSeven = state.starredEventList.filter(
        e =>
          e.data.eventDate &&
          e.data.eventDate > now &&
          e.data.eventDate < endDate
      );
      let sorted = nextSeven.sort((a, b) => {
        var val;
        if (a.data.eventDate === b.data.eventDate) return 0;
        if (a.data.eventDate < b.data.eventDate) return -1;
        return 1;
      });
      return sorted.slice(0, 5);
    },
    starredEvent: state => id => {
      return state.starredEvents[id];
    },
    starredEventList: state => state.starredEventList
  }
};

export default bookmarks;
