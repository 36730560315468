<template>
  <div class="faq-block">
    <div class="faq-entry">
      <div class="faq">How do I know what time an event is happening?</div>

      <div class="answer">
        The <a @click="showDetailImage = true">time for events</a> automatically
        adjust for users based on their own time zone. For example, if a
        California winery is hosting an event at Noon (PST), a user from New
        York will see that the event is happening for them at 15:00 (EST), for
        someone in London 20:00 (BST) and so on.
      </div>
      <ImagePopup
        src="/faq/details-page.png"
        :show-image="showDetailImage"
        @close="showDetailImage = false"
      ></ImagePopup>
    </div>

    <div class="faq-entry">
      <div class="faq">
        I went to look for an event that just started and I cannot find it.
      </div>
      <div class="answer">
        After about 10 minutes past an event’s starting time, the listing will
        be moved from the live page. However, you can still find them by
        scrolling down to the bottom of the page and clicking on the link that
        says
        <a @click="showPastEventImage = true">
          &ldquo;Some events today have already passed, click to display
          them&rdquo;
        </a>
      </div>
      <ImagePopup
        src="/faq/past-events.png"
        :show-image="showPastEventImage"
        @close="showPastEventImage = false"
      ></ImagePopup>
    </div>

    <div class="faq-entry">
      <div class="faq">
        I’m looking for an event that starts tomorrow at 9:30 am (or 11 pm) and
        cannot find it.
      </div>

      <div class="answer">
        <p>
          As a global site, we get a lot of online wine events submitted for
          each day. To make the listings more useful and less overwhelming,
          dates in the future (like tomorrow) will prioritize events happening
          between 10:00 and 22:00 in your time zone. You can still see all the
          events happening that day by clicking on the
          <a @click="showTenToTenImage = true">
            &ldquo;Show All Events&rdquo;
          </a>
          button to the right of the Events between 10AM - 10 PM tab.
        </p>
        <p>
          However, the current day will always show all upcoming events for that
          day till midnight in your time zone.
        </p>
      </div>
      <ImagePopup
        src="/faq/10-to-10.png"
        :show-image="showTenToTenImage"
        @close="showTenToTenImage = false"
      ></ImagePopup>
    </div>

    <div class="faq-entry">
      <div class="faq">Is this site only for U.S. based wine events?</div>

      <div class="answer">
        Not at all! VirtualWineEvents.com attracts visitors from countries
        across the globe looking for online wine events to attend. While now it
        has a predominantly English-speaking focus, there are plans to branch
        out to other languages. But anyone hosting an online wine event can
        submit a listing.
      </div>
    </div>

    <div class="faq-entry">
      <div class="faq">Who can submit events?</div>

      <div class="answer">
        Anyone that is holding an online wine event is welcome to submit a
        listing. This includes wineries, PR firms, wine educators, influencers
        and other small businesses hosting an event with a wine-related theme.
      </div>
    </div>

    <div class="faq-entry">
      <div class="faq">How can I submit events?</div>
      <div class="answer">
        <p>
          To submit an event, you must first create a login by
          <a @click="showLoginImage = true">clicking on the green button</a>
          that you see in the upper right-hand corner by the calendar page.
        </p>

        <p>
          Once you have logged in, click on the
          <a @click="showAddEventImage = true">green Add Event</a> button near
          the top of the page. That will take you to the
          <a @click="showInputImage = true">event detail page</a>. There we
          need:
        </p>

        <ol class="info-list">
          <li>Event Name</li>
          <li>Host Information</li>
          <li>
            Event Link – This the link that the user will be directed to when
            they click on your event’s name.
          </li>
          <li>Event Description</li>
        </ol>

        <p>
          Do note that our search engine looks at the event’s name, description
          and host information when returning
          <a @click="showSearchImage = true"> search results </a>. It is a good
          idea to make these as descriptive as you can so that people who may be
          interested in your event can find it.
        </p>
      </div>
      <ImagePopup
        src="/faq/login-button.png"
        :show-image="showLoginImage"
        @close="showLoginImage = false"
      ></ImagePopup>
      <ImagePopup
        src="/faq/input-page.png"
        :show-image="showInputImage"
        @close="showInputImage = false"
      ></ImagePopup>
      <ImagePopup
        src="/faq/add-event.png"
        :show-image="showAddEventImage"
        @close="showAddEventImage = false"
      ></ImagePopup>
      <ImagePopup
        src="/faq/yoga-search.png"
        :show-image="showSearchImage"
        @close="showSearchImage = false"
      ></ImagePopup>
    </div>

    <div class="faq-entry">
      <div class="faq">I just submitted an event. Why do I not see it?</div>

      <div class="answer">
        All events submitted must be approved by an admin before going live. We
        do strive to approve things quickly but there may be a delay for events
        submitted between 21:00 and 9:00 Central European Time (Noon to Midnight
        PST).
      </div>
    </div>

    <div class="faq-entry">
      <div class="faq">Can I edit an event after I submitted it?</div>

      <div class="answer">
        <p>
          A submitted event can be edited while it is still in ‘pending’ before
          it has been approved by admins. After submitting an event, you will
          see in the
          <a @click="showPendingImage = true"> upper right corner</a> that you
          have events pending. Clicking on this link will take you to an edit
          screen.
        </p>
        <p>
          However, once the event has been approved and has gone live on the
          website, only admins can edit the listing. To make changes, you can
          email us at
          <a
            href="mailto:support@virtualwineevents.com?subject='Request to change an event'"
          >
            support@virtualwineevents.com
          </a>
        </p>
      </div>
      <ImagePopup
        src="/faq/pending-events.png"
        :show-image="showPendingImage"
        @close="showPendingImage = false"
      ></ImagePopup>
    </div>
    <div class="faq-entry">
      <div class="faq">Are only free wine events allowed?</div>

      <div class="answer">
        No. While we have category tags to highlight free events, we also
        feature events with admission fees.
      </div>
    </div>

    <div class="faq-entry">
      <div class="faq">
        What is the difference between a Virtual Wine Tasting, Webinar and
        Social Event?
      </div>

      <div class="answer">
        <p>
          Ultimately, the host parties decide how to categorize their events.
          But some general guidelines:
        </p>
        <ul>
          <li>
            Virtual Tastings- Events focused on specific wines to be tasted,
            usually with an expectation that participants have pre-purchased the
            wines ahead of the event.
          </li>

          <li>
            Webinars- Events focused on a particular topic (such as Old Vine
            Zinfandel or the Wines of Rioja) that may include tasting specific
            wines but are structured to where participants don’t necessarily
            need to have those exact wines in order to enjoy the event. This
            would also include master classes and other educational events.
          </li>

          <li>
            Social Events- This is a broad category that includes trivia,
            quizzes and other wine games, virtual happy hours, , yoga, painting
            and cooking classes, book clubs, Twitter chats, movie viewing
            parties, etc.
          </li>
          <li>
            Instagram Live- Due to the growing popularity of these events, we've
            created a separate category to highlight them. IG Lives often
            incorporate elements of Virtual Tastings, Webinars and Social Events
            with the common link being the convenience of being able to easily
            drop in on them while they're happening.
          </li>
        </ul>
      </div>
    </div>

    <div class="faq-entry">
      <div class="faq">
        Do you allow Twitter Chats and Instagram or Facebook Live Events?
      </div>

      <div class="answer">
        Most definitely! We recommend making it clear in the Event Title and
        Description section which platform the event is being held on and how
        people can follow along. For the Event Link, you should have it going to
        either the FB event page or Instagram post/Tweet announcing the event.
      </div>
    </div>

    <div class="faq-entry">
      <div class="faq">
        What are Trending Events?
      </div>

      <div class="answer">
        The
        <a @click="showTrendingImage = true"> Trending Events section </a>
        highlights which online wine events, happening in the next week, are
        generating the most “buzz” and interest from users. The list updates
        roughly every hour with rankings based on how much interaction a listing
        is getting from users.
        <a @click="showTrendingInfluencesImage = true"> This includes </a>:

        <ul>
          <li>
            Events being “starred” or bookmarked as interesting (requires a log
            in)
          </li>
          <li>
            Events being added to a user’s Google, Outlook, Yahoo or iCal
            calendars
          </li>
          <li>
            Outbound clicks on the event title or the host’s social media
            handles
          </li>
          <li>Social media shares of the event</li>
        </ul>
      </div>
      <ImagePopup
        src="/faq/trending-events.png"
        :show-image="showTrendingImage"
        @close="showTrendingImage = false"
      ></ImagePopup>
      <ImagePopup
        src="/faq/trending-influences.png"
        :show-image="showTrendingInfluencesImage"
        @close="showTrendingInfluencesImage = false"
      ></ImagePopup>
    </div>
    <div class="faq-entry">
      <div class="faq">
        How can I bookmark an event?
      </div>

      <div class="answer">
        <p>
          To bookmark an event, you simply need to create a log in as noted
          above under the question “<b>How can I submit events?</b>”
        </p>

        <p>
          Then any event that you star (icon to the left of the event’s title)
          will be added to your personal bookmark section. This section can be
          access by clicking on the
          <a @click="showBookmarkImage = true"> Bookmarks link </a> to the right
          of the Trending Events tab.
        </p>

        <p>
          The Bookmark section will show the next 5 upcoming events that you
          have bookmarked. Clicking on
          <a @click="showBookmarkImage = true"> “View all my bookmarks” </a>
          will take you to a full listing of both current and previous
          bookmarks.
        </p>
      </div>
      <ImagePopup
        src="/faq/bookmarks.png"
        :show-image="showBookmarkImage"
        @close="showBookmarkImage = false"
      ></ImagePopup>
    </div>

    <div class="faq-entry">
      <div class="faq">
        What does the “Must Register” tag on the bottom of an event mean?
      </div>

      <div class="answer">
        This is just a category tag, like “Free”, “Webinar”, “Virtual Tasting”,
        etc., that we feature at the bottom of a listing. To register for the
        event, click on the event’s title and follow the host’s instructions.
      </div>
    </div>

    <div class="faq-entry">
      <div class="faq">
        How do I know that the details of the events are accurate?
      </div>
      <div class="answer">
        <p>
          At VirtualWineEvents.com, we strive to make sure that the events
          listed on our site are legitimate with accurate details and
          descriptions. However, we are not liable for any inaccuracies or
          changes made to the event by the host.
        </p>

        <p>
          We are purely a listing service with no affiliation with any of the
          host parties.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import ImagePopup from "@/components/widgets/ImagePopup";

import analytics from "../../models/analytics";

export default {
  name: "Faq",
  components: { ImagePopup },
  data() {
    return {
      showLoginImage: false,
      showDetailImage: false,
      showInputImage: false,
      showAddEventImage: false,
      showSearchImage: false,
      showPendingImage: false,
      showPastEventImage: false,
      showTenToTenImage: false,
      showBookmarkImage: false,
      showTrendingImage: false,
      showTrendingInfluencesImage: false
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    analytics.screenView();
  }
};
</script>

<style scoped>
.faq-block {
  margin: 22px 0px;
}
.faq {
  margin-bottom: 12px;
}
.faq-entry {
  margin: 9px 30px;
}
.faq-entry .faq {
  font-weight: bold;
}
.faq-entry .answer {
  margin-left: 1.25rem;
}
p {
  padding-bottom: 8px;
}
ul {
  padding: 5px 30px;
  list-style-type: square;
}
ul li {
  padding: 7px 0;
}
.info-list {
  padding-bottom: 8px;
  padding-left: 40px;
}
a {
  text-decoration: underline;
}
</style>
