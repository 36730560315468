<template>
  <div>
    <div class="date-form" v-if="!loading && !notFound">
      <div class="field">
        <label class="label">Event Name</label>
        <div class="control">
          <input
            class="input"
            type="text"
            v-model="eventName"
            :class="{ 'is-danger': !validName() }"
          />
        </div>
        <div class="is-danger help" v-if="!validName()">
          Please include a name for the event.
        </div>
      </div>
      <div class="field" v-if="dateInPast() || pastEvent">
        <label class="label">Link to Video</label>
        <div class="control">
          <input
            class="input"
            :class="{ 'is-danger': !validURL(videoLink, true) }"
            placeholder="http://"
            v-model="videoLink"
          />
        </div>
        <div class="is-danger help" v-if="!validURL(videoLink, true)">
          A valid URL is required
        </div>
      </div>
      <div class="field">
        <label class="label">Host Infomation</label>
      </div>
      <div class="field">
        <p class="control  has-icons-left">
          <input
            class="input"
            type="text"
            placeholder="Name"
            v-model="host.name"
            :class="{ 'is-danger': !validHostName() }"
          />
          <span class="icon is-small is-left">
            <i class="fas fa-user"></i>
          </span>
        </p>
        <div class="is-danger help" v-if="!validHostName()">
          Please specify the host's name.
        </div>
      </div>
      <div class="field is-grouped">
        <p class="control has-icons-left">
          <input
            class="input"
            type="text"
            placeholder="Twitter"
            v-model="host.social.twitter"
          />
          <span class="icon is-small is-left">
            <i class="fab fa-twitter"></i>
          </span>
        </p>
        <p class="control has-icons-left">
          <input
            class="input"
            type="text"
            placeholder="Instagram"
            v-model="host.social.instagram"
          />
          <span class="icon is-small is-left">
            <i class="fab fa-instagram"></i>
          </span>
        </p>
      </div>
      <div class="field">
        <label class="label">Date ({{ timezone() }})</label>
      </div>
      <div class="field is-grouped">
        <div class="control picker">
          <flatPickr
            class="input"
            v-model="date"
            :config="dateConfig"
            placeholder="Select Date"
          >
          </flatPickr>
        </div>
        <div class="control">
          <div class="select">
            <select v-model="hour">
              <option v-for="i in 24" :key="i"> {{ i - 1 }}</option>
            </select>
          </div>
          <span class="time-break">:</span>
          <div class="select">
            <select v-model="minute">
              <option>00</option>
              <option>15</option>
              <option>30</option>
              <option>45</option>
            </select>
          </div>
        </div>
        <span class="date-review"> {{ eventDateTime }} </span>
      </div>

      <div class="field">
        <label class="label">Event Link</label>
        <div class="control">
          <input
            class="input"
            :class="{ 'is-danger': !validURL(link) }"
            placeholder="http://"
            v-model="link"
          />
        </div>
        <div class="is-danger help" v-if="!validURL(link)">
          A valid URL is required
        </div>
      </div>
      <div class="field">
        <label class="label">Event Image (optional)</label>
        <dropzone
          ref="imgDropZone"
          id="customdropzone"
          :options="dropzoneOptions"
          @vdropzone-complete="afterComplete"
          @vdropzone-drop="hideDrop"
          v-if="showFileUpload && !eventImage"
        ></dropzone>
        <div v-if="images.length > 0" class="image-div">
          <div v-for="image in images" :key="image.src">
            <figure class="image">
              <a
                class="delete image-delete"
                @click="removeImage"
                v-if="eventImage"
              ></a>
              <img :src="image.src" />
            </figure>
          </div>
        </div>
      </div>
      <div class="field">
        <label class="label">Category</label>
        <div class="control">
          <label class="radio">
            <input
              type="radio"
              name="category"
              value="tasting"
              v-model="category"
            />
            Virtual Tasting
          </label>
          <label class="radio">
            <input
              type="radio"
              name="category"
              value="webinar"
              v-model="category"
            />
            Webinar
          </label>
          <label class="radio">
            <input
              type="radio"
              name="category"
              value="social"
              v-model="category"
            />
            Social Event
          </label>
          <label class="radio">
            <input
              type="radio"
              name="category"
              value="IG Live"
              v-model="category"
            />
            IG Live
          </label>
          <label class="radio">
            <input
              type="radio"
              name="category"
              value="other"
              v-model="category"
            />
            Other
          </label>
        </div>
      </div>

      <div class="field">
        <label class="label">Description</label>
        <div class="control">
          <textarea class="textarea" v-model="description" />
        </div>
        <div class="is-danger help" v-if="!validDesc()">
          Please include a description.
        </div>
      </div>

      <div class="field has-addons is-grouped">
        <div class="control magic-space">
          <label class="checkbox">
            <input type="checkbox" v-model="needsRegistration" />
            Requires Registration
          </label>
        </div>
        <div class="control">
          <label class="checkbox">
            <input type="checkbox" v-model="hasFee" />
            <span v-if="dateInPast() || pastEvent"> Video </span>
            Has Fee
          </label>
        </div>
        <div class="field has-addons has-addons-centered" v-if="hasFee">
          <div class="control">
            <span class="select">
              <select v-model="currency">
                <option>$</option>
                <option>£</option>
                <option>€</option>
              </select>
            </span>
          </div>
          <div class="control">
            <input class="input" type="text" v-model="fee" />
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-label">
          <!-- Left empty for spacing -->
        </div>
        <div class="field-body">
          <div class="field">
            <div class="control">
              <button
                class="button is-primary"
                @click="saveEvent"
                :disabled="!validForm()"
              >
                {{ eventId ? "Update" : "Add" }} Event
              </button>
            </div>
          </div>
        </div>
      </div>
      <ErrorModal
        :is-visible="showError"
        error-title="Error Saving Event"
        error-message="Something went wrong, and we were unable to save your event."
        @close="showError = false"
      ></ErrorModal>
    </div>
    <Spinner v-if="loading"></Spinner>
  </div>
</template>

<script>
//TODO: Update this class to pull the event sorting logic out.
import flatPickr from "vue-flatpickr-component";

import moment from "moment-timezone";
import { v4 as uuidv4 } from "uuid";
import db from "@/firebase/firestore";
import firebase from "@/firebase/firebase";
import errors from "@/models/errors";

import vweUser from "@/models/user";

import ErrorModal from "@/components/display/ErrorModal";
import Spinner from "@/components/display/Spinner";

import dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {
  name: "EventEditor",

  components: { ErrorModal, flatPickr, Spinner, dropzone },
  props: {
    "start-date": Object,
    "event-id": String,
    "event-table": { type: String, default: "events" },
    "past-event": {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: this.eventId,
      notFound: false,
      date: this.getStartDate(),
      dateConfig: this.makeDateConfig(),
      hasFee: false,
      needsRegistration: false,
      eventName: "",
      host: {
        name: "",
        social: {
          twitter: "",
          instagram: ""
        }
      },
      eventImage: null,
      imageToken: null,
      hour: "20",
      minute: "00",
      category: "",
      link: "http://",
      videoLink: "http://",
      description: "",
      currency: "$",
      fee: "0.00",
      location: "N. America",
      // non event related details
      showError: false,
      showFileUpload: true,
      dropzoneOptions: {
        url: "/",
        thumbnailWidth: 150,
        thumbnailHeight: 150,
        addRemoveLinks: false,
        acceptedFiles: ".jpg, .jpeg, .png",
        maxFiles: 1,
        dictDefaultMessage: `<p class='text-default'><i class='fa fa-cloud-upload mr-2'></i> Drag Image or Click Here</p>
          <p class="form-text">Allowed Files: .jpg, .jpeg, .png</p>
          `
      },
      images: [],
      origSubmitter: null
    };
  },
  computed: {
    eventDateTime() {
      var eventHourStr = this.hour;
      if (eventHourStr.length == 1) {
        eventHourStr = "0" + eventHourStr;
      }
      let date = this.date.concat(" ", eventHourStr, ":", this.minute);
      return moment(date).format("(h:mm A)");
    },
    submitter() {
      if (this.origSubmitter) {
        return this.origSubmitter;
      }
      return vweUser.user;
    }
  },
  methods: {
    makeDateConfig() {
      let minDate = "today";
      let maxDate = null;
      if (vweUser.isAdmin()) {
        minDate = null;
      }
      if (this.pastEvent && !vweUser.isAdmin()) {
        maxDate = "today";
        minDate = null;
      }
      return {
        altInput: true,
        altFormat: "F j, Y",
        minDate: minDate,
        maxDate: maxDate
      };
    },
    getStartDate() {
      if (this.startDate) {
        return this.startDate.format("YYYY-MM-DD");
      }
      return moment().format("YYYY-MM-DD");
    },
    timezone() {
      return moment.tz(moment.tz.guess()).format("zz");
    },
    dateInPast() {
      let dayOfEvent = getEventDate(this.date, this.hour, this.minute).format(
        "YYYY-MM-DD"
      );
      let today = moment().format("YYYY-MM-DD");
      return today > dayOfEvent;
    },
    saveEvent() {
      let eventDate = getEventDate(this.date, this.hour, this.minute);
      if (eventDate.invalid) {
        errors.logError(
          `Invalid date detected for _${this.date}_${this.hour}:${this.minute}`
        );
      }
      // Clear out the video URL if the event hasn't happened yet.  This will prevent admin errors where the link
      // gets added multiple times.
      if (!this.dateInPast()) {
        this.videoLink = null;
      }
      let event = {
        name: this.eventName,
        date: eventDate.utc().format(),
        link: this.link,
        description: this.description,
        eventImage: this.eventImage,
        imageToken: this.imageToken,
        categories: [],
        cost: "0",
        host: {},
        updatedAt: moment()
          .utc()
          .format(),
        submittedBy: this.submitter
      };
      if (
        this.videoLink &&
        (vweUser.isAdmin() || this.pastEvent) &&
        this.validURL(this.videoLink)
      ) {
        event.videoUrl = this.videoLink;
      }

      if (event.date.startsWith("Invalid")) {
        errors.logError("Invalid date saved: " + eventDate);
      }
      // set up the host
      event.host = {
        contact_info: this.host.social,
        name: this.host.name
      };

      // get the categories
      if (!this.hasFee) {
        event.categories.push("free");
      } else {
        if (this.dateInPast() || this.pastEvent) {
          if (this.validURL(this.videoLink)) {
            event.videoHasFee = true;
          }
        }
        event.cost = this.currency + this.fee;
      }
      if (this.needsRegistration) {
        event.categories.push("must-register");
      }
      if (this.category !== "" && this.category !== "other") {
        event.categories.push(this.category);
      }

      // we have the event, now save it.
      if (!this.eventId) {
        db.collection("pending_events")
          .add(event)
          .then(ref =>
            this.$router.push({ name: "Events", params: { msg: "pending" } })
          )
          .catch(err => {
            this.showError = true;
            errors.logException(err);
            console.error(err);
          });
      } else {
        db.collection(this.eventTable)
          .doc(this.eventId)
          .update(event)
          .then(ref =>
            this.$router.push({
              name: "Events",
              params: { msg: "update-event" }
            })
          )
          .catch(err => {
            this.showError = true;
            errors.logException(err);
            console.error(err);
          });
      }
    },
    validURL(url, adminBlankOk) {
      // protect against undefined being passed here.  Clearly a non valid object is not a valid URL
      if (!url) {
        if (adminBlankOk && vweUser.isAdmin()) {
          return true;
        }
        return false;
      }
      let pattern = /^(?:(?:https?):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
      return pattern.test(url.toLocaleLowerCase());
    },
    validDesc() {
      return this.description.length >= 3;
    },
    validName() {
      return this.eventName.length >= 3;
    },
    validHostName() {
      return this.host.name.length > 0;
    },
    validForm() {
      return (
        this.validURL(this.link) &&
        (!this.dateInPast() ||
          this.validURL(this.videoLink) ||
          vweUser.isAdmin()) &&
        this.validDesc() &&
        this.validName() &&
        this.validHostName()
      );
    },
    async afterComplete(upload) {
      this.showFileUpload = false;
      this.images.push({ src: "/spin.gif" });
      let imageName = uuidv4();
      this.isLoading = true;
      try {
        //save image
        let file = upload;
        let metadata = {
          contentType: "image/png"
        };
        let storageRef = firebase.storage().ref();
        let imageRef = storageRef.child("images/" + imageName + ".png");
        await imageRef.put(file, metadata);
        let downloadURL = await imageRef.getDownloadURL();
        this.images.pop();
        this.images.push({ src: downloadURL });
        this.eventImage = downloadURL;
        this.imageToken = imageName;
      } catch (error) {
        errors.logException(error);
        console.error(error);
      }
    },
    hideDrop() {
      this.showFileUpload = false;
    },
    removeImage() {
      this.showFileUpload = true;
      this.images = [];
      this.eventImage = null;
    },
    matchReg() {
      if (this.hasFee) {
        this.needsRegistration = true;
      }
    }
  },
  watch: {
    hasFee: "matchReg"
  },
  created() {
    if (this.eventId) {
      db.collection(this.eventTable)
        .doc(this.$route.params.id)
        .get()
        .then(item => {
          this.loading = false;
          if (!item.exists) {
            this.notFound = true;
            this.$emit("not-found");
            return;
          }
          populateEventState(this, item.data());
          this.id = item.id;
        })
        .catch(e => {
          console.error(e);
          errors.logException(e);
          this.$emit("not-found");
          this.notFound = true;
        });
    }
  }
};

function populateEventState(t, event) {
  t.date = moment(event.date).format("YYYY-MM-DD");
  t.hasFee = event.cost !== "0";
  t.needsRegistration = event.categories.includes("must-register");
  t.eventName = event.name;
  t.host = {
    name: event.host.name,
    social: {
      twitter: event.host.contact_info.twitter,
      instagram: event.host.contact_info.instagram
    }
  };
  t.videoLink = event.videoUrl;
  t.hour = moment(event.date).format("HH");
  t.minute = moment(event.date).format("mm");
  if (t.hour.startsWith("0")) {
    t.hour = t.hour.substring(1);
  }
  if (event.imageToken !== undefined) {
    t.imageToken = event.imageToken;
  }
  if (event.categories.includes("webinar")) {
    t.category = "webinar";
  } else if (event.categories.includes("tasting")) {
    t.category = "tasting";
  } else if (event.categories.includes("social")) {
    t.category = "social";
  } else if (event.categories.includes("IG live")) {
    t.category = "IG Live";
  }
  t.link = event.link;
  t.description = event.description;
  if (t.hasFee) {
    t.currency = event.cost.substring(0, 1);
    t.fee = event.cost.substring(1);
  }
  if (event.eventImage) {
    t.eventImage = event.eventImage;
    t.images.push({ src: event.eventImage });
  }
  t.origSubmitter = event.submittedBy;
}

function getEventDate(date, hour, minute) {
  let tz = moment.tz.guess(true);
  moment.tz.setDefault(tz);
  if (hour.length == 1) {
    hour = "0" + hour;
  }
  let strDate = date + " " + hour + ":" + minute;
  return moment(strDate);
}
</script>

<style scoped>
.date-form {
  margin-left: 40px;
  margin-right: 20px;
}

.picker {
  width: 360px;
}

.control.picker {
  margin: 0;
}

.magic-space {
  padding-bottom: 15px;
}

.time-break {
  font-size: 22px;
  padding-left: 6px;
  padding-right: 6px;
}

.image-div {
  display: flex;
  margin: 25px;
}
.image {
  max-width: 250px;
  margin: 15px;
}
.image-delete {
  position: absolute;
  top: 2px;
  right: 1px;
  right: 1px;
}
.date-review {
  font-size: 17px;
  padding-top: 7px;
}
</style>
