<template>
  <div class="field">
    <div class="control">
      <flatPickr
        class="input no-input"
        v-model="date"
        :config="config"
        placeholder="Select Date"
      >
      </flatPickr>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
import flatPickr from "vue-flatpickr-component";

export default {
  name: "DatePicker",
  props: ["route_name"],
  components: { flatPickr },
  data() {
    return {
      date: getStartDate(this.$route),
      config: {
        inline: true
      }
    };
  },
  methods: {
    syncRoute() {
      if (this.$route.name == "Events") {
        this.date = moment().format("YYYY-MM-DD");
        return;
      }
      if (this.$route.name != "EventsFor") {
        return;
      }
      this.date =
        this.$route.params.year +
        "-" +
        this.$route.params.month +
        "-" +
        this.$route.params.day;
    },
    updateRoute() {
      let comps = this.date.split("-");
      if (comps.length != 3) return;
      let props = {
        year: parseInt(comps[0]),
        month: parseInt(comps[1]),
        day: parseInt(comps[2])
      };

      if (
        this.$route.name != "EventsFor" ||
        !routesEq(props, this.$route.params)
      ) {
        this.$router.push({ name: "EventsFor", params: props });
      }
    }
  },
  watch: {
    $route: "syncRoute",
    date: "updateRoute"
  }
};

function routesEq(ob1, ob2) {
  if (ob1.year.toString() !== ob2.year.toString()) {
    return false;
  }
  if (ob1.month.toString() !== ob2.month.toString()) {
    return false;
  }
  if (ob1.day.toString() !== ob2.day.toString()) {
    return false;
  }
  return true;
}

function getStartDate(route) {
  if (route.name == "EventsFor") {
    let date =
      route.params.year + "-" + route.params.month + "-" + route.params.day;
    return date;
  }
  return moment().format("YYYY-MM-DD");
}
</script>
<style scoped>
.no-input {
  display: none;
}
</style>
