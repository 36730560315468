// Utility functions for routing
import vweUser from "@/models/user";

export default {
  checkLoggedIn(from, to, next) {
    if (vweUser.loggedIn()) {
      next();
    } else {
      next({
        name: "Error",
        params: { err: "You must be logged in to view that page." }
      });
    }
  },
  checkAdmin(from, to, next) {
    if (vweUser.loggedIn() && vweUser.isAdmin()) {
      next();
    } else {
      next({
        name: "Error",
        params: { err: "You do not have permission to view that page." }
      });
    }
  }
};
