<template>
  <div>
    <div class="err-msg notification is-danger is-small is-light" v-if="renderError">
      <button class="delete is-small" @click="renderError = false"></button>
      {{ err }}
    </div>
    <h2>That page cannot be displayed.</h2>
  </div>
</template>
<script>
export default {
  name: "Error",
  props: ["err"],
  data() {
    return {
      renderError: this.err != undefined
    };
  }
};
</script>
<style scoped>
.err-msg  {
    margin:2px 20px;
}
</style>
