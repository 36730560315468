<template>
  <div class="modal" :class="{ 'is-active': shown }">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ title }}</p>
      </header>
      <section class="modal-card-body">
        {{ message }}
      </section>
      <footer class="modal-card-foot">
        <button class="button is-success" @click="onYes">Yes</button>
        <button class="button is-danger" @click="onNo">No</button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: "confirmation.bue",
  props: {
    shown: { type: Boolean, default: false },
    title: { type: String, default: "Are You Sure?" },
    message: { type: String, default: "Do you want to take this action?" }
  },
  methods: {
    onYes() {
      this.$emit("yes");
    },
    onNo() {
      this.$emit("no");
    }
  }
};
</script>

<style scoped></style>
