<template>
  <div class="dropdown is-up" :class="{ 'is-active': showCalMenu }">
    <div class="dropdown-trigger">
      <button
        class="button cal-button is-link"
        @click="showCalMenu = !showCalMenu"
      >
        <span class="icon is-small">
          <i class="fas fa-calendar"> </i>
        </span>
        <span>
          Add to your calendar
        </span>
      </button>
    </div>
    <div class="dropdown-menu">
      <div class="dropdown-content">
        <a
          class="dropdown-item"
          target="_blank"
          :href="googleCalLink()"
          @click="
            () => {
              showCalMenu = false;
              addToCalendar();
            }
          "
        >
          <i class="fab fa-google cal-icon"></i>
          Google Calendar
        </a>
        <a
          class="dropdown-item"
          :href="outlookLink()"
          target="_blank"
          @click="
            () => {
              showCalMenu = false;
              addToCalendar();
            }
          "
        >
          <i class="fab fa-microsoft cal-icon"></i>
          Outlook Web Calendar
        </a>
        <a
          class="dropdown-item"
          :href="yahooLink()"
          target="_blank"
          @click="
            () => {
              showCalMenu = false;
              addToCalendar();
            }
          "
        >
          <i class="fab fa-yahoo cal-icon"></i>
          Yahoo Calendar
        </a>
        <a
          class="dropdown-item"
          @click="
            () => {
              downloadIcal();
              showCalMenu = false;
            }
          "
        >
          <i class="fal fa-calendar-alt cal-icon"></i>
          iCal
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
import { ICalendar, OutlookCalendar, YahooCalendar } from "datebook";

export default {
  name: "AddToCalendar",
  props: {
    event: { type: Object }
  },
  data() {
    return {
      showCalMenu: false
    };
  },
  methods: {
    // Generate a link to add your event to google calendar.
    googleCalLink() {
      let fmtstart = moment(this.event.date)
        .utc()
        .format("YYYYMMDDTHHmm00");
      let fmtend = moment(this.event.date)
        .add(1, "hour")
        .utc()
        .format("YYYYMMDDTHHmm00");
      // Yes this is ugly, but it's the google cal link.
      return `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(
        this.event.name
      )}&dates=${fmtstart}Z/${fmtend}Z&details=${encodeURIComponent(
        this.truncatedCalEntry(this.event.description)
      )}`;
    },
    // Information for the calendar
    eventCalInfo() {
      let start = moment(this.event.date)
        .utc()
        .toDate();
      let end = moment(this.event.date)
        .add(1, "hour")
        .utc()
        .toDate();
      return {
        title: this.event.name,
        description: this.truncatedCalEntry(this.event.description),
        start: start,
        end: end
      };
    },
    outlookLink() {
      return new OutlookCalendar(this.eventCalInfo()).render();
    },
    yahooLink() {
      return new YahooCalendar(this.eventCalInfo()).render();
    },
    downloadIcal() {
      let icalendar = new ICalendar(this.eventCalInfo());
      this.addToCalendar(); // make sure we mark this as interesting if we cal.
      icalendar.download();
    },
    truncatedCalEntry(entry) {
      if (entry.length < 1020) {
        return entry;
      }
      return `${entry.substring(0, 1012)}...`;
    },
    addToCalendar() {
      this.$emit("added");
    }
  }
};
</script>

<style scoped>
.cal-button {
  font-size: 11px;
}
.cal-icon {
  margin-right: 6px;
}
</style>
