<template>
  <ul class="trending-list">
    <li v-for="evt in trending" :key="evt.id" class="trending-detail">
      <div class="trending-desc">
        <router-link
          :to="{ name: 'ViewEvent', params: { id: evt.id } }"
          @click.native="visitTrending"
        >
          {{ evt.name }}
        </router-link>
        hosted by {{ evt.host }}
        <span v-if="evt.videoHasFee" class="has-fee">(Fee)</span>
      </div>
    </li>
  </ul>
</template>

<script>
import moment from "moment-timezone";

import analytics from "../../models/analytics";
import vweUser from "../../models/user";

export default {
  name: "TrendingEventList",
  props: ["page"],
  data() {
    return {
      eventList: [],
      firstEvent: null
    };
  },
  methods: {
    visitTrending() {
      if (!vweUser.isAdmin()) {
        analytics.logEvent("trending_video_viewed");
      }
    }
  },
  computed: {
    trending() {
      return this.$store.getters.trendingReplays;
    }
  },
  created() {
    this.$store.dispatch("loadTrendingReplays");
  }
};
</script>

<style scoped>
.trending-list {
  list-style-type: circle;
  margin-left: 8px;
}
.trending-detail {
  font-size: 0.8em;
}
.trending-desc {
  margin-left: 10px;
}
.has-fee {
  color: #900000;
}
</style>
