<template>
  <div class="about-us">
    <h2 class="title is-4">About Virtual Wine Events</h2>
    <img class="author-image" src="/amber.jpg" align="left" />
    <p>
      Virtual Wine Events was started in April 2020 by Amber LeBeau of
      <a href="https://spitbucket.net">Spitbucket.net</a> during the lockdown
      due to COVID-19. She wanted to create a site that would make it easy to
      find and share the growing number of events that were happening online.
    </p>
    <p>
      This site is very much a work in progress, we would welcome any feedback
      you might have. For more details about how this site came about, check out
      our post on the
      <a
        href="https://spitbucket.net/2020/05/04/a-new-tool-to-help-promote-online-wine-events/"
      >
        Spitbucket blog </a
      >.
    </p>
    <p>You can reach Virtual Wine Events at events@spitbucket.net</p>
  </div>
</template>

<script>
import analytics from "../../models/analytics";
export default {
  name: "About",
  mounted() {
    analytics.screenView();
  }
};
</script>

<style scoped>
.about-us {
  padding: 10px 60px;
}
img.author-image {
  padding-right: 12px;
}

.about-us p {
  margin: 12px 0px;
}
</style>
