<template>
  <div class="vwe">
    <NavBar></NavBar>
    <div class="columns">
      <div class="column is-two-thirds buffered">
        <router-view />
      </div>
      <div class="column">
        <UserToolBox></UserToolBox>
        <div class="date-header">Select a date:</div>
        <DatePicker class="picker" route_name="EventsFor" />
        <TrendingPanel></TrendingPanel>
        <AdminToolBox v-if="showAdmin()" class="box-format" />
        <div class="twitter-follow-div">
          <span class="twitter-follow-lead">For updates: </span>
          <span class="twitter-follow-link">
            <a
              href="https://twitter.com/VirtualWineEvts?ref_src=twsrc%5Etfw"
              class="twitter-follow-button"
              data-show-count="false"
              >Follow @VirtualWineEvts</a
            ></span
          >
        </div>
      </div>
    </div>
    <cookie-law
      theme="vwe"
      button-decline
      button-decline-text="No thanks"
      button-text="Ok!"
      message="To help ensure that you get the best experience on this website, we use cookies."
      @accept="activateCookies()"
    ></cookie-law>
    <Footer />
  </div>
</template>

<script>
import NavBar from "./components/display/NavBar";
import DatePicker from "./components/display/DatePicker";
import Footer from "./components/support/Footer";
import AdminToolBox from "./components/admin/AdminToolBox";
import UserToolBox from "./components/layouts/UserToolBox";
import TrendingPanel from "./components/layouts/TrendingPanel";

import vweUser from "./models/user";
import CookieLaw from "vue-cookie-law";
import firebase from "@/firebase/firebase";
export default {
  name: "App",
  components: {
    NavBar,
    DatePicker,
    Footer,
    AdminToolBox,
    CookieLaw,
    UserToolBox,
    TrendingPanel
  },
  data() {
    return {
      user: vweUser
    };
  },
  methods: {
    showAdmin() {
      if (this.user == null || this.user == undefined) {
        return false;
      }
      return this.user.isAdmin();
    },
    activateCookies() {
      localStorage["ga-on"] = true;
      if (!localStorage["ga-on"]) {
        firebase.analytics();
      }
    }
  },
  beforeMount() {
    var elem = document.querySelector("#initial");
    elem.parentElement.removeChild(elem);
  }
};
</script>

<style>
.vwe {
  font-family: Lato, Palatino, Arial, sans-serif;
}

.buffered {
  padding-right: 35px;
}

.date-header {
  font-size: 18px;
  padding-left: 25px;
  padding-bottom: 8px;
}
.picker {
  margin-right: 10px;
  padding-bottom: 4px;
}
.Cookie--vwe {
  background: hsl(220, 15%, 15%);
  color: #d0d0d0;
  padding: 2.25em;
  font-size: 1.15em;
}
.Cookie--vwe .Cookie__button {
  background: hsl(360, 65%, 40%);
  padding: 0.625em 3.125em;
  color: #fff;
  border-radius: 0;
  border: 0;
  font-size: 1.1em;
}
.Cookie--vwe .Cookie__button:hover {
  background: hsl(360, 70%, 55%);
}
.Cookie--vwe .Cookie__button--decline {
  background: transparent;
  padding: 0.625em 3.125em;
  color: #909090;
  border-radius: 0;
  border: 0;
  font-size: 1em;
}
.Cookie--vwe .Cookie__button--decline:hover {
  background: hsl(220, 15%, 25%);
}

.twitter-follow-div {
  text-align: center;
}
.twitter-follow-lead {
  position: relative;
  font-size: 16px;
  top: -5px;
}
.twitter-follow-link {
  margin: 5px auto;
}
.box-format {
  margin-right: 20px;
}
</style>
