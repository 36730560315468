<template>
  <div>
    <div
      class="err-msg notification is-danger is-small is-light"
      v-if="showError"
    >
      <button class="delete is-small" @click="showError = false"></button>
      There was a problem updating the database. Please try later.
    </div>
    <h2>Changes Pending Approval</h2>
    <div class="pending-event media" v-for="event in events" :key="event.id">
      <div class="event-approval media-left">
        <div>
          <button
            class="button button-one is-success"
            @click="approveChange(event)"
          >
            Approve!
          </button>
        </div>
        <div>
          <button
            class="button is-danger button-two"
            @click="rejectChange(event)"
          >
            Reject
          </button>
        </div>
      </div>
      <div class="event-info">
        <EventCard class="event-card" :event="event" date_format="LLL">
        </EventCard>
      </div>
    </div>
  </div>
</template>

<script>
import EventCard from "../display/EventCard";
import errors from "@/models/errors";
import db from "../../firebase/firestore";

export default {
  name: "UpdateApproval",
  components: { EventCard },
  data() {
    return {
      events: [],
      showError: false
    };
  },
  methods: {
    approveChange(pendingEvent) {
      if (pendingEvent.videoUrl) {
        pendingEvent["search_weight"] = 12;
      }
      let id = pendingEvent.id;

      delete pendingEvent.id;
      if (pendingEvent.oldVideoUrl) {
        delete pendingEvent.oldVideoUrl;
      }
      if (pendingEvent.oldCost) {
        delete pendingEvent.oldCost;
      }
      // Copy it to the events table, then delete from pending and display.
      db.collection("events")
        .doc(id)
        .set(pendingEvent)
        .then(ref => {
          db.collection("pending_event_updates")
            .doc(id)
            .delete()
            .catch(err => {
              console.error(err);
              errors.logException(err);
              this.showError = true;
            });
          pendingEvent.id = id;
          this.events = this.events.filter(e => e.id !== id);
        })
        .catch(err => {
          console.error(err);
          errors.logException(err);
          this.showError = true;
        });
    },
    rejectChange(pendingEvent) {
      // delete and then remove it from the display
      db.collection("pending_event_updates")
        .doc(pendingEvent.id)
        .delete()
        .then(
          () =>
            (this.events = this.events.filter(e => e.id !== pendingEvent.id))
        )
        .catch(err => {
          this.showError = true;
          errors.logException(err);
          console.error(err);
        });
    }
  },
  created() {
    db.collection("pending_event_updates")
      .orderBy("date")
      .get()
      .then(snapshot => {
        snapshot.forEach(doc => {
          let event = doc.data();
          event.id = doc.id;
          this.events.push(event);
        });
      });
  }
};
</script>

<style scoped>
h2 {
  font-size: 22px;
  text-align: center;
  font-family: Lato, Palatino, Arial, sans-serif;
}

.pending-event {
  margin: 20px 40px;
}

.event-approval {
  padding-top: 60px;
}

.button-one {
  width: 100%;
}

.button-two {
  margin-top: 8px;
  width: 100%;
}
</style>
