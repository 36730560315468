import Vuex from "vuex";
import Vue from "vue";
import Meta from "vue-meta";
import VueSocialSharing from "vue-social-sharing";

import db from "../firebase/firestore";

import bookmarks from "./modules/bookmarks";
import events from "./modules/events";
import replays from "./modules/trending_replays";
import trending from "./modules/trending";
import user from "./modules/user";

Vue.use(Vuex);
Vue.use(Meta);
Vue.use(VueSocialSharing);

let store = new Vuex.Store({
  modules: {
    bookmarks,
    events,
    replays,
    trending,
    user
  }
});

export default store;
