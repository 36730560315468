import { render, staticRenderFns } from "./EditEvent.vue?vue&type=template&id=0d26c9fa&scoped=true&"
import script from "./EditEvent.vue?vue&type=script&lang=js&"
export * from "./EditEvent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d26c9fa",
  null
  
)

export default component.exports