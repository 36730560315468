var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"date-nav"},[_c('div',{attrs:{"id":"event-header"}},[(_vm.renderNotification)?_c('div',{staticClass:"notification is-primary is-small is-light"},[_c('button',{staticClass:"delete is-small",on:{"click":function($event){_vm.renderNotification = false}}}),_vm._v(" "+_vm._s(_vm.formatMessage(_vm.message))+" ")]):_vm._e(),_c('div',{attrs:{"id":"date-selector"}},[_c('DateSelector',{attrs:{"start_date":_vm.curDate},on:{"new-date":_vm.changeDate}})],1),(_vm.user() != null)?_c('div',{attrs:{"id":"add-event"}},[_c('button',{staticClass:"button is-primary",on:{"click":_vm.addEvent}},[_vm._m(0),_c('span',[_vm._v("Add Event")])])]):_vm._e(),(_vm.user() === null)?_c('div',{attrs:{"id":"add-event-disabled"}},[_c('button',{staticClass:"button is-primary",attrs:{"disabled":""},on:{"click":_vm.addEvent}},[_vm._m(1),_c('span',[_vm._v("Login to Add Event")])])]):_vm._e(),_c('div',{staticClass:"clear"})]),_c('DesktopEventViewer',{staticClass:"is-hidden-touch",attrs:{"upcoming-events":_vm.upcomingEventsForDay(_vm.curDate),"past-events":_vm.pastEventsForToday(),"cur-date":_vm.curDate},on:{"deleted":_vm.deleteEvent}}),_c('MobileEventViewer',{staticClass:"is-hidden-desktop",attrs:{"upcoming-events":_vm.upcomingEventsForDay(_vm.curDate),"past-events":_vm.pastEventsForToday(),"cur-date":_vm.curDate},on:{"deleted":_vm.deleteEvent}}),(_vm.loading && _vm.allEventsForDay(_vm.curDate).length == 0)?_c('Spinner'):_vm._e(),(
      !_vm.loading &&
        _vm.upcomingEventsForDay(_vm.curDate).length == 0 &&
        !(_vm.showingToday(_vm.curDate) && _vm.pastEventsForToday().length > 0)
    )?_c('div',[_c('p',{staticClass:"subtitle is-5 no-events nudge-right"},[_vm._v(" There are no events for this day. ")])]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon is-small"},[_c('i',{staticClass:"fa fa-calendar-edit"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon is-small"},[_c('i',{staticClass:"fa fa-calendar-edit"})])
}]

export { render, staticRenderFns }