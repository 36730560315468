<template>
  <div>
    <section class="hero">
      <div class="hero-body">
        <div class="container">
          <h1 class="title">
            Here are upcoming {{ displayNameFromCat(category) }}
          </h1>
          <h2 class="subtitle">
            Please click on an event for more information.
          </h2>
        </div>
      </div>
    </section>
    <div class="notification is-danger is-small is-light" v-if="showError">
      <button class="delete is-small" @click="showError = false"></button>
      There was an error retrieving the data from the server. Please try again
      later.
    </div>
    <Spinner v-if="loading"> </Spinner>
    <div v-if="!loading && events.length == 0">
      <p class="subtitle is-5 nudged">
        There are no events of this type coming up.
      </p>
    </div>
    <EventListItem
      v-for="event in currentView"
      :key="event.id"
      :event="event"
    ></EventListItem>
    <Paginator
      :items="events"
      :page-size="12"
      :name="category"
      class="pagination-widget"
      @updateDisplayedItems="updatePage"
    ></Paginator>
  </div>
</template>

<script>
import moment from "moment-timezone";

import analytics from "../../models/analytics";
import errors from "@/models/errors";
import db from "@/firebase/firestore";

import EventListItem from "../display/EventListItem";
import Spinner from "../display/Spinner";
import Paginator from "../widgets/Paginator";

export default {
  name: "EventList",
  components: { EventListItem, Spinner, Paginator },
  data() {
    return {
      category: this.$route.params.category,
      showError: false,
      events: [],
      currentView: [],
      loading: false
    };
  },
  methods: {
    updatePage(newList) {
      this.currentView = newList;
    },
    update() {
      this.events = [];
      this.category = this.$route.params.category;
      loadEventsOfType(this.category, this);
    },
    capitalize(word) {
      return word.substring(0, 1).toUpperCase() + word.substring(1);
    },
    displayNameFromCat(cat) {
      return displayable(cat);
    }
  },
  metaInfo() {
    let metaObj = {
      link: [
        {
          rel: "canonical",
          href:
            "https://virtualwineevents.com/events/type/" +
            this.$route.params.category
        }
      ],
      meta: [
        {
          name: "description",
          content: `A listing of upcoming ${displayable(
            this.$route.params.category
          )} submitted by users from around the web.`
        }
      ]
    };
    return metaObj;
  },
  watch: {
    $route: "update"
  },
  created() {
    document.title = `Virtual Wine Events: ${this.capitalize(
      this.category
    )} events coming up.`;
  },
  mounted() {
    loadEventsOfType(this.category, this);
    analytics.screenView();
  }
};

function displayable(str) {
  if (str == undefined) {
    return "Events of All Types";
  }
  switch (str) {
    case "webinar":
      return "Webinars";
    case "tasting":
      return "Virtual Tastings";
    case "social":
      return "online wine Social Events";
    default:
      return `${str.charAt(0).toUpperCase() + str.slice(1)} Events`;
  }
}
function loadEventsOfType(category, target) {
  target.loading = true;
  let now = moment().utc();
  let events = target.events;

  db.collection("events")
    .where("date", ">=", now.format())
    .where("categories", "array-contains", category)
    .orderBy("date")
    .get()
    .then(snapshot => {
      target.loading = false;
      snapshot.forEach(doc => {
        let event = doc.data();
        event.id = doc.id;
        events.push(event);
        target.$store.dispatch("storeEvent", event);
      });
    })
    .catch(err => {
      console.error("Error loading the events from the DB!");
      errors.logException(err);
      target.showError = true;
      target.loading = false;
    });
}
</script>

<style scoped>
li {
  list-style-type: none;
}
.nudged {
  padding-left: 45px;
}
.pagination-widget {
  margin: 35px 40px;
}
</style>
