<template>
  <div>
    <div class="notification is-danger is-small is-light" v-if="showError">
      <button class="delete is-small" @click="showError = false"></button>
      There was an error retrieving the data. Please try again later.
    </div>
    <div v-if="events.length > 0">
      <p class="pending-header">
        You have submitted the following events that are awaiting approval:
      </p>

      <ul class="eventList">
        <li v-for="event in events" :key="event.id" class="eventEntry">
          {{ event.name }} at {{ formatDate(event.date) }}
          <router-link :to="{ name: 'EditPending', params: { id: event.id } }"
            >(edit)</router-link
          >
        </li>
      </ul>
    </div>
    <div v-if="!loading && events.length == 0" class="ïs-primary">
      You have no submitted events that are pending approval.
    </div>
    <Spinner v-if="loading"> </Spinner>
  </div>
</template>

<script>
import moment from "moment-timezone";

import analytics from "../../models/analytics";
import db from "@/firebase/firestore.js";
import vweUser from "@/models/user";
import Spinner from "@/components/display/Spinner";

export default {
  name: "ViewPendingEvents",
  components: { Spinner },
  data() {
    return {
      events: [],
      loading: false,
      showError: false
    };
  },
  methods: {
    formatDate(date) {
      return moment(date).format("LLL");
    }
  },
  created() {
    this.loading = true;
    db.collection("pending_events")
      .where("submittedBy.id", "==", vweUser.user.id)
      .orderBy("date")
      .get()
      .then(snapshot => {
        this.loading = false;
        snapshot.forEach(doc => {
          let event = doc.data();
          event.id = doc.id;
          this.events.push(event);
        });
      })
      .catch(err => {
        console.error("Error loading the events from the DB: ", err);
        this.showError = true;
        this.loading = false;
      });
  },
  mounted() {
    analytics.screenView();
  }
};
</script>

<style scoped>
.pending-header {
  font-size: 18px;
}
.eventList {
  margin: 10px 35px;
}
</style>
