<template>
  <div>
    <div
      class="notification is-success is-small is-light"
      v-if="showSaveSuccess"
    >
      <button class="delete is-small" @click="showSaveSuccess = false"></button>
      Your profile has been updated successfully.
    </div>
    <div class="notification is-danger is-small is-light" v-if="showSaveError">
      <button class="delete is-small" @click="showSaveError = false"></button>
      Your profile could not be updated. Please try again later.
    </div>
    <div class="profile-form">
      <div class="field">
        <label class="label">Name</label>
        <div class="control">
          <input class="input" type="text" v-model="name" />
        </div>
      </div>

      <div class="field">
        <label class="label">Email</label>
        <div class="control ">
          <input class="input" type="email" v-model="email" />
        </div>
      </div>

      <div class="field">
        <label class="label">Twitter</label>
        <div class="control has-icons-left">
          <span class="icon is-small is-left">
            <i class="fab fa-twitter"></i>
          </span>
          <input class="input" type="text" v-model="twitter" />
        </div>
      </div>

      <div class="field">
        <label class="label">Instagram</label>
        <div class="control has-icons-left">
          <span class="icon is-small is-left">
            <i class="fab fa-instagram"></i>
          </span>
          <input class="input" type="text" v-model="instagram" />
        </div>
      </div>

      <div class="field">
        <p class="control">
          <button class="button is-success" @click="updateProfile">
            Save
          </button>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import vweUser from "@/models/user";
import analytics from "../../models/analytics";

export default {
  name: "Profile",
  data() {
    return {
      user: vweUser,
      name: vweUser.user != null ? vweUser.user.name : "",
      email: vweUser.user != null ? vweUser.user.email : "",
      twitter: vweUser.user != null ? vweUser.user.twitter : "",
      instagram: vweUser.user != null ? vweUser.user.instagram : "",
      showSaveSuccess: false,
      showSaveError: false
    };
  },
  methods: {
    updateUser() {
      if (vweUser.user == null) {
        this.$router.push({ name: "Events" });
        return;
      }
      this.name = vweUser.user.name;
      this.email = vweUser.user.email;
      this.twitter = vweUser.user.twitter != null ? vweUser.user.twitter : "";
      this.instagram =
        vweUser.user.instagram != null ? vweUser.user.instagram : "";
    },
    updateProfile() {
      this.showSaveSuccess = false;
      this.showSaveError = false;
      vweUser.updateProfile(
        {
          name: this.name,
          email: this.email,
          twitter: this.twitter,
          instagram: this.instagram
        },
        () => {
          this.showSaveSuccess = true;
        },
        () => {
          this.showSaveError = true;
        }
      );
    }
  },
  watch: {
    "user.user.twitter": "updateUser"
  },
  mounted() {
    analytics.screenView();
  }
};
</script>

<style scoped>
.profile-form {
  margin: 10px 35px;
}
</style>
