// Vuex module to handle events.
import Vue from "vue";
import db from "../../firebase/firestore";

let cacheTimeOut = 10 * 60 * 1000;

let events = {
  state: {
    eventCache: {},
    dayCache: {}
  },
  mutations: {
    // Store all of the events for a day
    storeDayEvents(state, payload) {
      let expiration = Date.now() + cacheTimeOut;
      Vue.set(state.dayCache, payload.day, {
        expireAt: expiration,
        events: payload.events
      });
      payload.events.forEach(evt => {
        Vue.set(state.eventCache, evt.id, {
          expireAt: expiration,
          event: evt
        });
      });
    },
    // store an event.
    storeEvent(state, event) {
      let expiration = Date.now() + cacheTimeOut;
      Vue.set(state.eventCache, event.id, {
        expireAt: expiration,
        event: event
      });
    }
  },
  actions: {
    storeDayEvents(context, { day, events }) {
      context.commit("storeDayEvents", { day, events });
    },
    storeEvent(context, event) {
      context.commit("storeEvent", event);
    },
    // This action kicks of the process of loading an event.  There are callback functions at the
    // start and end of the event to make it easier to see what's going on.
    async loadEvent(context, { id, start, done }) {
      if (start) {
        start();
      }
      let cached = context.getters.event(id);
      // this is an empty promise.
      var promise = new Promise(resolve => resolve(null));
      if (!cached) {
        promise = db
          .collection("events")
          .doc(id)
          .get()
          .then(doc => {
            if (doc.exists) {
              let event = doc.data();
              event.id = doc.id;
              context.commit("storeEvent", event);
            }
          });
      }
      if (done) {
        done();
      }
      return promise;
    }
  },
  getters: {
    // Check the cache for events for the day
    eventsForDay: state => day => {
      if (state.dayCache[day]) {
        let hit = state.dayCache[day];
        if (hit.expireAt < Date.now()) {
          return null;
        }
        return hit.events;
      }
      return null;
    },
    // check the cache
    event: state => id => {
      if (state.eventCache[id]) {
        let hit = state.eventCache[id];
        if (Date.now() < hit.expireAt) {
          return hit.event;
        }
      }
      return null;
    }
  }
};

export default events;
