<template>
  <div>
    <div class="event-view">
      <CompactEditor
        class="event-card"
        :event="event"
        v-if="event"
        date_format="LLL"
        :editable-fields="['video-url']"
      >
      </CompactEditor>
    </div>
    <div v-if="notFound" class="notification is-danger">
      The event could not be found. Please check the id on your link, and try
      again.
    </div>
    <div>
      <Spinner class="is-centered" v-if="!event && !notFound"></Spinner>
    </div>
  </div>
</template>

<script>
import db from "@/firebase/firestore";
import errors from "../../models/errors";

import CompactEditor from "../display/CompactEditor";
import Spinner from "../display/Spinner";
export default {
  name: "UserUpdate",
  components: { CompactEditor, Spinner },
  data() {
    return {
      event: null,
      notFound: false
    };
  },
  computed: {
    eventId() {
      return this.$route.params.id;
    }
  },
  methods: {
    getEvent() {
      if (!this.eventId) {
        return null;
      }
      let event = this.$store.getters.event(this.eventId);
      return event;
    }
  },
  mounted() {
    this.event = this.getEvent();
    if (this.event) {
      return;
    }
    // We should probably have the event, but if not, let's try to load it.
    db.collection("events")
      .doc(this.eventId)
      .get()
      .then(item => {
        if (!item.data()) {
          console.log("Error retrieving event. - data was nil");
          this.notFound = true;
          return;
        }
        this.notFound = false;
        let newEvent = item.data();
        newEvent.id = item.id;
        this.$store.dispatch("storeEvent", newEvent);
        this.event = newEvent;
      })
      .catch(e => {
        this.notFound = true;
        errors.logException(e);
        console.error(e);
      });
  }
};
</script>

<style scoped></style>
