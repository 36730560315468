// methods to help log and track errors that happen to users.

import * as Sentry from "@sentry/browser";
import Vue from "vue";

let sentry = {
  active: false,
  init() {
    Sentry.init({
      Vue,
      dsn:
        "https://067231bc3e1747988a23598a5eeba3b2@o390226.ingest.sentry.io/5231422"
    });
    this.active = true;
  },
  logError(err) {
    if (!this.active) {
      return;
    }
    Sentry.captureException(new Error(err));
  },
  logException(err) {
    if (!this.active) {
      return;
    }
    Sentry.captureException(err);
  },
  setUser(id, email) {
    if (!this.active) {
      return;
    }
    Sentry.configureScope(scope => {
      scope.setUser({ id: id, email: email });
    });
  }
};

export default sentry;
