<template>
  <div>
    <ViewChooser
      v-if="showChooser"
      @change="setActivePane"
      :panes="[
        { id: 'tenten', text: '10AM-10 PM' },
        { id: 'all', text: 'Show All' }
      ]"
      :initial="startWithAll() ? 'all' : 'tenten'"
      :name="curDate"
      class="chooser"
    ></ViewChooser>
    <div class="event-entry" v-for="event in filteredEvents" :key="event.id">
      <EventListItem class="event-card" :event="event"> </EventListItem>
    </div>
    <div
      class="no-more-text"
      v-if="
        showingToday(curDate) &&
          pastEvents.length > 0 &&
          upcomingEvents.length === 0
      "
    >
      There are no more events scheduled for today.
    </div>
    <div v-if="showingToday(curDate) && pastEvents.length > 0">
      <hr />
      <div
        class="has-text-centered pad-bottom"
        v-if="showingToday(curDate) && pastEvents.length > 0"
      >
        <a @click="showPastEvents = !showPastEvents">
          <span class="toggle-past-events" v-if="!showPastEvents">
            Some events today have already passed, click to display them
          </span>
          <span class="toggle-past-events" v-if="showPastEvents">
            Click to hide past events.
          </span>
        </a>
      </div>
    </div>
    <div
      class="event-entry grayed"
      v-if="showingToday(curDate) && pastEvents.length > 0 && showPastEvents"
      v-for="event in pastEvents"
      :key="event.id"
    >
      <EventListItem class="event-card" :event="event"> </EventListItem>
    </div>
  </div>
</template>

<script>
import EventListItem from "@/components/display/EventListItem";
import ViewChooser from "../widgets/ViewChooser";

import moment from "moment-timezone";
import { TenToTen } from "../../util/event_filters";

export default {
  name: "MobileEventViewer",
  props: ["cur-date", "past-events", "upcoming-events"],
  components: { EventListItem, ViewChooser },
  data() {
    return {
      showPastEvents: false,
      activePane: this.defaultPanes()
    };
  },
  computed: {
    filteredEvents() {
      if (this.activePane.tenten) {
        return TenToTen(this.upcomingEvents);
      }
      return this.upcomingEvents;
    },
    showChooser() {
      return this.showingFuture();
    }
  },
  methods: {
    startWithAll() {
      if (
        moment()
          .add(1, "day")
          .format("YYYYMMDD") === moment(this.curDate).format("YYYYMMDD")
      ) {
        if (moment().format("HH") === "23") {
          return true;
        }
      }
      return !this.showingFuture();
    },
    defaultPanes() {
      return {
        tenten: !this.startWithAll(),
        all: this.startWithAll()
      };
    },
    showingFuture() {
      return (
        moment(this.curDate).format("YYYYMMDD") > moment().format("YYYYMMDD")
      );
    },
    showingToday(date) {
      return moment(date).format("LL") === moment().format("LL");
    },
    setActivePane(evt) {
      for (let pane in this.activePane) {
        this.activePane[pane] = pane === evt.pane;
      }
    },
    resetDefaultPanes() {
      this.activePane = this.defaultPanes();
    }
  },
  watch: {
    curDate: "resetDefaultPanes"
  }
};
</script>

<style scoped>
.event-entry {
  display: flex;
  flex-flow: row nowrap;
}

.event-card {
  margin: 15px 20px 10px 20px;
  width: 100%;
}

.grayed {
  background-color: #e8e8e8;
}

.toggle-past-events {
  font-size: 11px;
}
.pad-bottom {
  padding-bottom: 12px;
  margin-top: -10px;
}
.no-more-text {
  margin-left: 15px;
}
.chooser {
  font-size: 0.9rem;
  margin: 0.7rem;
}
</style>
