<template>
  <div>
    <h2 class="title is-5 has-text-centered">Events I've submitted</h2>
    <div class="eventSection" v-if="loaded && upcomingEvents.length">
      <h2 class="subtitle is-5 has-text-centered">Upcoming Events</h2>
      <div
        class="event-entry is-flex"
        v-for="event in upcomingEvents"
        :key="event.id"
      >
        <div class="icon-container" v-if="showEditIcon">
          <span class="icon has-text-primary is-medium"
            ><i class="fas fa-calendar-edit fa-lg add-video-link"></i
          ></span>
        </div>
        <div class="event-detail">
          <router-link
            class="event-link"
            :to="{ name: 'ViewEvent', params: { id: event.id } }"
            >{{ event.name }}
          </router-link>
          on {{ eventDate(event) }} at {{ eventTime(event) }}
        </div>
      </div>
    </div>
    <div class="eventSection" v-if="loaded && pastEvents.length">
      <h2 class="subtitle is-5 has-text-centered">Past Events</h2>
      <div
        class="event-entry is-flex"
        v-for="event in pastEvents"
        :key="event.id"
      >
        <div class="icon-container" v-if="showVideoIcon">
          <router-link
            :to="{ name: 'UserUpdateEvent', params: { id: event.id } }"
          >
            <span class="icon has-text-primary is-medium"
              ><i class="fas fa-video-plus fa-lg add-video-link"></i
            ></span>
          </router-link>
        </div>
        <div class="event-detail">
          <router-link
            class="event-link"
            :to="{ name: 'ViewEvent', params: { id: event.id } }"
            >{{ event.name }}
          </router-link>
          on {{ eventDate(event) }} at {{ eventTime(event) }}
        </div>
      </div>
    </div>
    <div class="eventSection" v-if="loaded && !eventList.length">
      <h2 class="subtitle is-6 has-text-centered">
        You have not submitted any events
      </h2>
    </div>
    <Paginator
      :items="eventList"
      :page-size="14"
      class="pagination-widget"
      @updateDisplayedItems="updatePage"
    ></Paginator>
    <Spinner v-if="!loaded"></Spinner>
  </div>
</template>

<script>
import moment from "moment-timezone";

import errors from "../../models/errors";
import flags from "../../models/flags";
import db from "../../firebase/firestore";

import Paginator from "../widgets/Paginator";
import Spinner from "../display/Spinner";

export default {
  name: "DisplayMyEvents",

  components: { Spinner, Paginator },
  data() {
    return {
      eventList: [],
      displayedEvents: [],
      loaded: false
    };
  },
  computed: {
    userId() {
      return this.$store.getters.user.id;
    },
    upcomingEvents() {
      let now = moment()
        .utc()
        .format();
      return this.displayedEvents.filter(evt => now < evt.date);
    },
    pastEvents() {
      let now = moment()
        .utc()
        .format();
      return this.displayedEvents.filter(evt => now >= evt.date);
    },
    showVideoIcon() {
      return flags.addVideos;
    },
    showEditIcon() {
      return flags.updateEvents;
    }
  },
  methods: {
    eventDate(evt) {
      return moment(evt.date).format("LL");
    },
    eventTime(evt) {
      return moment(evt.date).format("LT");
    },
    updatePage(items) {
      this.displayedEvents = items;
    }
  },
  mounted() {
    db.collection("events")
      .where("submittedBy.id", "==", this.userId)
      .orderBy("date", "desc")
      .get()
      .then(ss => {
        ss.forEach(item => {
          let event = item.data();
          event.id = item.id;
          this.eventList.push(event);
        });
        this.loaded = true;
      })
      .catch(ex => {
        console.error("Error detected loading events.", ex);
        errors.logException(ex);
      });
  }
};
</script>

<style scoped>
.event-entry {
  margin-left: 2.5rem;
  margin-bottom: 0.5rem;
}
.add-video-link {
  margin-right: 1rem;
  margin-top: -0.25rem;
}
.event-detail {
  margin-left: 0.25rem;
}
.pagination-widget {
  margin: 35px 40px;
}
</style>
