var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.showChooser)?_c('ViewChooser',{attrs:{"panes":[
      { id: 'tenten', text: 'Events between 10AM - 10 PM' },
      { id: 'all', text: 'Show All Events' }
    ],"initial":_vm.startWithAll() ? 'all' : 'tenten',"name":_vm.curDate},on:{"change":_vm.setActivePane}}):_vm._e(),_vm._l((_vm.filteredEvents),function(event){return _c('div',{key:event.id,staticClass:"event-entry"},[(_vm.itActive())?_c('InterestTracker',{staticClass:"tracker",attrs:{"event":event,"disabled":_vm.now() > event.date}}):_vm._e(),_c('EventCard',{staticClass:"event-card",attrs:{"event":event},on:{"deleted":_vm.deleteEvent}})],1)}),(
      _vm.showingToday(_vm.curDate) &&
        _vm.pastEvents.length > 0 &&
        _vm.upcomingEvents.length === 0
    )?_c('div',{staticClass:"no-more-text"},[_vm._v(" There are no more events scheduled for today. ")]):_vm._e(),(_vm.showingToday(_vm.curDate) && _vm.pastEvents.length > 0)?_c('hr'):_vm._e(),(_vm.showingToday(_vm.curDate) && _vm.pastEvents.length > 0)?_c('div',{staticClass:"has-text-centered"},[_c('a',{on:{"click":function($event){_vm.showPastEvents = !_vm.showPastEvents}}},[(!_vm.showPastEvents)?_c('span',{staticClass:"toggle-past-events"},[_vm._v(" Some events today have already passed, click to display them ")]):_vm._e(),(_vm.showPastEvents)?_c('span',{staticClass:"toggle-past-events"},[_vm._v(" Click to hide past events. ")]):_vm._e()])]):_vm._e(),(_vm.showPastEvents)?_c('div',{staticClass:"past-events"},_vm._l((_vm.pastEvents),function(event){return (_vm.showingToday(_vm.curDate))?_c('div',{key:event.id,staticClass:"event-entry"},[(_vm.itActive())?_c('InterestTracker',{staticClass:"tracker",attrs:{"event":event,"disabled":true}}):_vm._e(),_c('EventCard',{staticClass:"event-card",attrs:{"event":event},on:{"deleted":_vm.deleteEvent}})],1):_vm._e()}),0):_vm._e(),_c('div',{staticClass:"reset"})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }