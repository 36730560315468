<template>
  <div>
    <h2 class="has-text-centered">Event Data Dashboard</h2>
    <div v-if="!loading">
      <table class="table">
        <tr>
          <th>Month</th>
          <th>Total Events</th>
          <th>Webinars</th>
          <th>Tastings</th>
          <th>Social</th>
          <th>IG Live</th>
          <th>With Videos</th>
        </tr>
        <tr v-for="month_data in eventData">
          <td>{{ month_data.name }}</td>
          <td>{{ month_data.total }}</td>
          <td>{{ month_data.webinar }}</td>
          <td>{{ month_data.tasting }}</td>
          <td>{{ month_data.social }}</td>
          <td>{{ month_data.igLive }}</td>
          <td>{{ month_data.videos }}</td>
        </tr>
      </table>
    </div>
    <div>
      <Spinner class="is-centered" v-if="loading"></Spinner>
    </div>
  </div>
</template>

<script>
import db from "@/firebase/firestore";
import moment from "moment-timezone";

import Spinner from "../display/Spinner";

export default {
  name: "EventDashboard",
  components: { Spinner },
  data() {
    return {
      rawData: new Map(),
      loading: true
    };
  },
  computed: {
    eventData() {
      let processedData = [];

      for (let [month, monthData] of this.rawData.entries()) {
        console.log(`${month}`, monthData);
        processedData.push({
          name: month,
          total: monthData["total"],
          webinar: monthData["webinar"],
          tasting: monthData["tasting"],
          social: monthData["social"],
          igLive: monthData["IG Live"],
          videos: monthData["videos"]
        });
      }
      return processedData.sort((a, b) => {
        if (b.name < a.name) {
          return -1;
        }
        if (b.name > a.name) {
          return 1;
        }
        return 0;
      });
    }
  },
  async mounted() {
    this.rawData = new Map();
    let total = {
      total: 0,
      webinar: 0,
      tasting: 0,
      social: 0,
      "IG Live": 0,
      videos: 0
    };
    let snapshot = await db
      .collection("events")
      .orderBy("date")
      .limit(300)
      .get();

    let cnt = 0;
    let curSS = snapshot;
    let lastRead = curSS.docs[curSS.docs.length - 1];
    let processSnapShot = ss => {
      ss.forEach(doc => {
        cnt += 1;
        let event = doc.data();
        // there are some dates that are null.
        if (!event.date) {
          return;
        }
        let key = moment(event.date).format("YYYY-MM");
        if (key === "Invalid date") {
          return false;
        }

        let month_data = this.rawData.get(key) || {
          total: 0,
          webinar: 0,
          tasting: 0,
          social: 0,
          "IG Live": 0,
          videos: 0
        };
        month_data.total += 1;
        total.total += 1;
        for (let cat of event.categories) {
          month_data[cat] = (month_data[cat] || 0) + 1;
          total[cat] += 1;
        }
        if (event.videoUrl) {
          month_data.videos = (month_data.videos || 0) + 1;
          total.videos += 1;
        }
        this.rawData.set(key, month_data);
        this.rawData.set("Total", total);
      });
    };
    while (curSS.docs.length > 0) {
      console.log("Starting Batch, read so far is ", cnt);
      processSnapShot(curSS);
      curSS = await db
        .collection("events")
        .orderBy("date")
        .startAfter(lastRead)
        .limit(300)
        .get();
      lastRead = curSS.docs[curSS.docs.length - 1];
    }
    this.rawData = new Map(this.rawData.entries());
    this.loading = false;
  }
};
</script>

<style scoped></style>
