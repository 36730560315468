<template>
  <ul class="trending-list">
    <li v-for="evt in trending" :key="evt.id" class="trending-detail">
      <div>{{ getEventDate(evt.date) }}</div>
      <div class="trending-desc">
        <router-link
          :to="{ name: 'ViewEvent', params: { id: evt.id } }"
          @click.native="visitTrending"
        >
          {{ evt.name }}
        </router-link>
      </div>
    </li>
  </ul>
</template>

<script>
import moment from "moment-timezone";

import analytics from "../../models/analytics";
import vweUser from "../../models/user";

export default {
  name: "TrendingEventList",
  props: ["page"],
  data() {
    return {
      eventList: [],
      firstEvent: null
    };
  },
  methods: {
    getEventDate(date) {
      return moment(date).format("llll");
    },
    visitTrending() {
      if (!vweUser.isAdmin()) {
        analytics.logEvent("trending_viewed");
      }
    }
  },
  computed: {
    trending() {
      return this.$store.getters.upcomingTrendingEvents(this.page);
    }
  },
  created() {
    this.$store.dispatch("loadTrending", "3");
  }
};
</script>

<style scoped>
.trending-list {
  list-style-type: circle;
  margin-left: 8px;
}
.trending-detail {
  font-size: 0.825em;
}
.trending-desc {
  margin-left: 10px;
}
</style>
