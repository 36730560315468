<template>
  <span
    class="tag"
    :class="tagClasses"
    @mouseover="tagHover = true"
    @mouseleave="tagHover = false"
  >
    <a
      :href="url"
      v-if="linkify"
      @click="$emit('event-followed')"
      target="_blank"
    >
      <span> {{ tag }}</span>
    </a>
    <span v-else> {{ tag }}</span>
  </span>
</template>

<script>
function appendAll(array, items) {
  for (const item of items) {
    array.push(item);
  }
  return array;
}
export default {
  name: "EventTag",
  props: ["tag", "url"],
  data() {
    return {
      tagHover: false
    };
  },
  computed: {
    linkify() {
      return this.tag === "must-register";
    },
    tagClasses() {
      let base =
        this.tagHover && this.tag === "must-register" ? ["danger-hover"] : [];
      if (this.tag === "free") {
        return appendAll(base, ["is-success"]);
      }
      if (this.tag === "must-register") {
        return appendAll(base, ["is-danger"]);
      }
      return appendAll(base, ["is-light", "is-info"]);
    }
  }
};
</script>

<style scoped>
.tag:not(body).is-danger a {
  background-color: #f14668;
  color: #fff;
}
.is-danger.danger-hover {
  background-color: #ce0f35;
}
.tag.is-danger.danger-hover a {
  background-color: #ce0f35;
}
</style>
