// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import "./compat.js";

import Vue from "vue";

import App from "./App.vue";
import router from "./router";
import errors from "./models/errors";

import store from "./store";
import "flatpickr/dist/flatpickr.css";
import "./assets/date-picker-theme.css";

require("./styles/vwe.scss");

if (process.env.NODE_ENV === "production") {
  errors.init();
}

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
