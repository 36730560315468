import Vue from "vue";
import Router from "vue-router";

import PrivacyPolicy from "@/components/support/Privacy";
import ErrorPage from "@/components/support/Error";
import AboutUs from "@/components/support/About";
import Faq from "@/components/support/Faq";

import eventRoutes from "./events";
import userRoutes from "./user";
import adminRoutes from "./admin";

Vue.use(Router);

let miscRoutes = [
  {
    path: "/error",
    name: "Error",
    component: ErrorPage,
    props: true
  },
  {
    path: "/docs/privacy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy
  },
  {
    path: "/docs/faq",
    name: "FAQ",
    component: Faq
  },
  {
    path: "/about",
    name: "AboutUs",
    component: AboutUs
  }
];

const router = new Router({
  mode: "history",
  routes: []
    .concat(miscRoutes)
    .concat(eventRoutes)
    .concat(userRoutes)
    .concat(adminRoutes),
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

// This code borrows from https://github.com/vuejs/vue-router/issues/2932#issuecomment-533453711
// It's needed to handle the guards blocking a router push action.
const originalPush = router.push;
router.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  }
  return originalPush.call(this, location).catch(err => {
    if (err) {
      // If there really is an error, throw it
      // Should probably be more sophisticated based on the type of err
      return Promise.reject(err);
    }
    // Otherwise resolve to false to indicate the original push call didn't go to its original
    // destination.
    // TODO: return the final route instead of false?
    return Promise.resolve(false);
  });
};

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  } else {
    document.title = "Virtual Wine Events: Tastings, Webinars, and more!";
  }
  if (!to.meta.hasDesc) {
    var meta = document.createElement("meta");
    meta.name = "description";
    meta.content =
      "Virtual Wine Events is a free searchable listing of virtual wine tastings, online wine classes, wine webinars, and more.";
    document.getElementsByTagName("head")[0].appendChild(meta);
  }

  next();
});

export default router;
