<template>
  <div class="window-control">
    <ul class="window-control-links is-horizontal-list">
      <li v-for="item in panes" :key="item.id">
        <a
          :class="{ 'is-active': activePane[item.id] }"
          @click="setActivePane(item.id)"
          >{{ item.text }}</a
        >
      </li>
    </ul>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  name: "ViewChooser",
  props: {
    panes: {
      type: Array,
      required: true
    },
    initial: {
      type: String,
      required: true
    },
    name: { type: Object }
  },
  data() {
    return {
      activePane: {}
    };
  },
  methods: {
    setActivePane(activeId) {
      for (var pane of this.panes) {
        this.activePane[pane.id] = activeId == pane.id;
      }
      this.$emit("change", { pane: activeId });
    },
    defaultPanes() {
      let activePane = {};
      for (var pane of this.panes) {
        activePane[pane.id] = pane.id == this.initial;
      }
      return activePane;
    },
    resetPanes() {
      Vue.set(this, "activePane", this.defaultPanes());
      this.$emit("change", { pane: this.initial });
    }
  },
  created() {
    this.activePane = this.defaultPanes();
  },
  watch: {
    name: "resetPanes"
  }
};
</script>

<style scoped>
.is-horizontal-list {
  align-items: flex-end;
  display: flex;
  justify-content: center;
}

.is-horizontal-list li {
  display: inline;
  padding: 0.5em 3.5em;
}

.window-control-links a.is-active {
  color: black;
  text-decoration: underline;
}
</style>
