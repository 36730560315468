// Collection of feature flags to handle rollout.
import vwe from "./user";
let production = process.env.NODE_ENV === "production";

let flags = {
  itActive: true,
  addVideos: true,
  updateEvents: false
};

export default flags;
