<template>
  <div>
    <div
      class="notification is-primary padded"
      v-if="events.length == 0 && bookmarksLoaded"
    >
      You currently do not have any events bookmarked.
    </div>
    <div class="padded" v-else>
      <h2 class="subtitle is-5 indented">
        Your bookmarked events:
      </h2>
    </div>
    <ul class="event-list">
      <li
        v-for="(event, index) in displayedEvents"
        :key="event.id"
        @updateDisplayedItems="updatePage"
      >
        <EventListItem
          :event="eventForId(event.id)"
          v-if="eventForId(event.id)"
        ></EventListItem>
      </li>
    </ul>
    <Paginator
      :items="sortedEvents"
      :page-size="10"
      class="pagination-widget"
      @updateDisplayedItems="updatePage"
    ></Paginator>
  </div>
</template>

<script>
import EventListItem from "../display/EventListItem";
import Paginator from "../widgets/Paginator";

import analytics from "../../models/analytics";

const PAGE_SIZE = 5;

export default {
  name: "ViewBookmarks",
  components: { EventListItem, Paginator },
  data() {
    return {
      page: 1,
      pageCount: 0,
      displayedEvents: []
    };
  },
  computed: {
    events() {
      return this.$store.getters.starredEventList;
    },
    sortedEvents() {
      let eventsWithDates = this.events.map(e => {
        let evt = this.$store.getters.starredEvent(e.id).data;
        evt.id = e.id;
        return evt;
      });
      return eventsWithDates.sort((e1, e2) => {
        if (e1.eventDate > e2.eventDate) return -1;
        if (e1.eventDate < e2.eventDate) return 1;
        return 0;
      });
    },
    bookmarksLoaded() {
      return this.$store.getters.bookmarksLoaded;
    }
  },
  watch: {
    events: "sortEvents"
  },
  methods: {
    updatePage(items) {
      this.displayedEvents = items;
    },
    async loadEvent(id) {
      await this.$store.dispatch("loadEvent", { id });
    },
    eventForId(id) {
      this.loadEvent(id);
      let evt = this.$store.getters.event(id);
      return evt;
    },
    sortEvents() {}
  },
  created() {
    this.sortEvents();
  },
  mounted() {
    analytics.screenView();
  }
};
</script>

<style scoped>
.subtitle.indented {
  margin-left: 20px;
}
.padded {
  margin-top: 35px;
}
.pagination-widget {
  margin: 35px 40px;
}
</style>
