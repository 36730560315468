<template>
  <div>
    <div
      class="err-msg notification is-danger is-small is-light"
      v-if="showError"
    >
      <button class="delete is-small" @click="showError = false"></button>
      There was a problem updating the database. Please try later.
    </div>
    <h2>Events Pending Approval</h2>
    <div class="pending-event media" v-for="event in events" :key="event.id">
      <div class="event-approval media-left">
        <div>
          <button
            class="button button-one is-success"
            @click="approveEvent(event)"
          >
            Approve!
          </button>
        </div>
        <div>
          <router-link :to="{ name: 'EditPending', params: { id: event.id } }">
            <button class="button is-info button-two">
              Edit Event
            </button>
          </router-link>
        </div>
        <div>
          <button
            class="button is-danger button-two"
            @click="rejectEvent(event)"
          >
            Reject
          </button>
        </div>
      </div>
      <div class="event-info space-fill">
        <EventCard
          class="event-card"
          :event="event"
          date_format="LLL"
          :video-approval="event.videoUrl ? true : false"
          :no_truncate="true"
        >
        </EventCard>
        <div class="submitter box">
          <span class="submitter-data"> Event Submitted by: </span>
          <div class="media submitter-data">
            <div class="media-left" v-if="event.submittedBy.avatar_url">
              <figure class="image is-32x32">
                <img :src="event.submittedBy.avatar_url" />
              </figure>
            </div>
            <span class="submitter-data media-content">
              {{ event.submittedBy.name }} <br />
              ({{ event.submittedBy.email }})
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import db from "@/firebase/firestore";
import errors from "@/models/errors";
import EventCard from "@/components/display/EventCard";

export default {
  name: "EventApproval.vue",
  components: { EventCard },
  data() {
    return {
      events: [],
      showError: false
    };
  },
  methods: {
    approveEvent(pendingEvent) {
      let id = pendingEvent.id;

      delete pendingEvent.id;
      // remove some things for privacy.
      pendingEvent.submittedBy = {
        id: pendingEvent.submittedBy.id
      };
      // Copy it to the events table, then delete from pending and display.
      db.collection("events")
        .add(pendingEvent)
        .then(ref => {
          db.collection("pending_events")
            .doc(id)
            .delete()
            .catch(err => {
              console.error(err);
              errors.logException(err);
              this.showError = true;
            });
          pendingEvent.id = id;
          this.events = this.events.filter(e => e.id !== id);
        })
        .catch(err => {
          console.error(err);
          errors.logException(err);
          this.showError = true;
        });
    },
    rejectEvent(pendingEvent) {
      // delete and then remove it from the display
      db.collection("pending_events")
        .doc(pendingEvent.id)
        .delete()
        .then(
          () =>
            (this.events = this.events.filter(e => e.id !== pendingEvent.id))
        )
        .catch(err => {
          errors.logException(err);
          this.showError = true;
        });
    }
  },
  created() {
    db.collection("pending_events")
      .orderBy("date")
      .get()
      .then(snapshot => {
        snapshot.forEach(doc => {
          let event = doc.data();
          event.id = doc.id;
          this.events.push(event);
        });
      });
  }
};
</script>

<style scoped>
h2 {
  font-size: 22px;
  text-align: center;
  font-family: Lato, Palatino, Arial, sans-serif;
}

.space-fill {
  min-width: 92%;
}

.pending-event {
  margin: 20px 40px;
}

.submitter {
  padding-left: 26px;
}

.submitter-data {
  display: inline-block;
  vertical-align: middle;
  font-weight: 700;
}

span.submitter-data {
  font-size: 10px;
}

.event-approval {
  padding-top: 60px;
}

.button-one {
  width: 100%;
}

.button-two {
  margin-top: 6px;
  width: 100%;
}
</style>
