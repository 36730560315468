<template>
  <EventEditor
    :start-date="this.date"
    :past-event="this.pastEvent"
  ></EventEditor>
</template>

<script>
import EventEditor from "@/components/display/EventEditor";
import analytics from "../../models/analytics";
export default {
  name: "AddEvent",
  props: {
    date: {
      type: Object
    },
    "past-event": {
      type: Boolean,
      default: false
    }
  },
  components: { EventEditor },
  mounted() {
    analytics.screenView();
  }
};
</script>

<style scoped></style>
