<template>
  <div class="date-selector">
    <span @click="changeDate(-1)">
      <i
        class="fas fa-caret-square-left chooser is-size-5-mobile is-size-5-tablet"
      >
      </i>
    </span>
    <span class="event-banner is-size-5-mobile is-size-4-tablet">
      Virtual Events for {{ strDate() }}
    </span>
    <span @click="changeDate(1)">
      <i
        class="fas fa-caret-square-right chooser is-size-5-mobile is-size-5-tablet"
      >
      </i>
    </span>
  </div>
</template>

<script>
import moment from "moment-timezone";

export default {
  name: "DateSelector",
  props: ["start_date"],
  data() {
    return {
      curDate: this.start_date
    };
  },
  methods: {
    // Function to display the date in the format that the user wants.
    strDate: function() {
      return this.curDate.format("LL");
    },
    // Function to update the date when we have a click.
    changeDate(val) {
      this.curDate = moment(this.curDate).add(val, "d");
      this.$emit("new-date", this.curDate);
    },
    updateDate() {
      this.curDate = this.start_date;
    }
  },
  watch: {
    start_date: "updateDate"
  }
};
</script>

<style scoped>
.event-banner {
  padding: 0 5px;
  font-family: Lato, Palatino, Arial, sans-serif;
}
</style>
