// Vuex store for trending details

import Vue from "vue";
import db from "../../firebase/firestore";
import moment from "moment-timezone";

let trendingStore = {
  state: {
    trendingEvents: []
  },
  mutations: {
    updateTrendingList(state, { page, events }) {
      Vue.set(state, "trendingEvents", events);
    }
  },
  actions: {
    refreshTrending(context, page) {
      db.collection("trending_list")
        .doc(page)
        .get()
        .then(doc => {
          context.commit("updateTrendingList", {
            page: page,
            events: doc.data().entries
          });
        });
    },
    loadTrending(context, page) {
      if (context.state.trendingEvents.length > 0) {
        return;
      }
      context.dispatch("refreshTrending", page);
      setInterval(() => {
        context.dispatch("refreshTrending", page);
      }, 1800 * 1000);
    }
  },
  getters: {
    upcomingTrendingEvents: state => page => {
      let evts = state.trendingEvents;
      let now = moment()
        .utc()
        .format();
      let filteredEvts = evts.filter(evt => evt.date > now);
      return filteredEvts.slice(0, 6);
    }
  }
};

export default trendingStore;
