// Routes related to the user.
import utils from "./utils";

import Profile from "@/components/layouts/Profile";
import DisplayMyEvents from "../components/layouts/DisplayMyEvents";

export default [
  {
    path: "/profile",
    name: "ViewProfile",
    component: Profile,
    beforeEnter: utils.checkLoggedIn
  },
  {
    path: "/myEvents",
    name: "MyEvents",
    component: DisplayMyEvents,
    beforeEnter: utils.checkLoggedIn
  }
];
