// Functions to handle the common event filtering while displaying events

import moment from "moment-timezone";

function TenToTen(events) {
  return events.filter(evt => {
    let date = moment(evt.date);
    return date.format("HH") >= "10" && date.format("HH") < "22";
  });
}

export { TenToTen };
