<template>
  <div>
    <ViewChooser
      v-if="showChooser"
      @change="setActivePane"
      :panes="[
        { id: 'tenten', text: 'Events between 10AM - 10 PM' },
        { id: 'all', text: 'Show All Events' }
      ]"
      :initial="startWithAll() ? 'all' : 'tenten'"
      :name="curDate"
    ></ViewChooser>

    <div class="event-entry" v-for="event in filteredEvents" :key="event.id">
      <InterestTracker
        class="tracker"
        :event="event"
        v-if="itActive()"
        :disabled="now() > event.date"
      ></InterestTracker>
      <EventCard class="event-card" :event="event" @deleted="deleteEvent">
      </EventCard>
    </div>
    <div
      class="no-more-text"
      v-if="
        showingToday(curDate) &&
          pastEvents.length > 0 &&
          upcomingEvents.length === 0
      "
    >
      There are no more events scheduled for today.
    </div>
    <hr v-if="showingToday(curDate) && pastEvents.length > 0" />
    <div
      class="has-text-centered"
      v-if="showingToday(curDate) && pastEvents.length > 0"
    >
      <a @click="showPastEvents = !showPastEvents">
        <span class="toggle-past-events" v-if="!showPastEvents">
          Some events today have already passed, click to display them
        </span>
        <span class="toggle-past-events" v-if="showPastEvents">
          Click to hide past events.
        </span>
      </a>
    </div>

    <div class="past-events" v-if="showPastEvents">
      <div
        class="event-entry"
        v-if="showingToday(curDate)"
        v-for="event in pastEvents"
        :key="event.id"
      >
        <InterestTracker
          class="tracker"
          :event="event"
          v-if="itActive()"
          :disabled="true"
        ></InterestTracker>

        <EventCard class="event-card" :event="event" @deleted="deleteEvent">
        </EventCard>
      </div>
    </div>
    <div class="reset"></div>
  </div>
</template>

<script>
import moment from "moment-timezone";

import InterestTracker from "../display/InterestTracker";
import EventCard from "../display/EventCard";

import { TenToTen } from "../../util/event_filters";
import flags from "../../models/flags";
import ViewChooser from "../widgets/ViewChooser";

export default {
  name: "DesktopEventViewer",
  props: ["cur-date", "past-events", "upcoming-events"],
  components: { ViewChooser, EventCard, InterestTracker },
  data() {
    return {
      showPastEvents: false,
      activePane: this.defaultPanes()
    };
  },
  computed: {
    filteredEvents() {
      if (this.activePane.tenten) {
        return TenToTen(this.upcomingEvents);
      }
      return this.upcomingEvents;
    },
    showChooser() {
      return this.showingFuture();
    }
  },
  methods: {
    showingFuture() {
      return (
        moment(this.curDate).format("YYYYMMDD") > moment().format("YYYYMMDD")
      );
    },
    startWithAll() {
      if (
        moment()
          .add(1, "day")
          .format("YYYYMMDD") === moment(this.curDate).format("YYYYMMDD")
      ) {
        if (moment().format("HH") === "23") {
          return true;
        }
      }
      return !this.showingFuture();
    },
    defaultPanes() {
      return {
        tenten: !this.startWithAll(),
        all: this.startWithAll()
      };
    },
    showingToday(date) {
      return moment(date).format("LL") === moment().format("LL");
    },
    deleteEvent(event) {
      this.$emit("deleted", event);
    },
    itActive() {
      return flags.itActive;
    },
    now() {
      return moment()
        .utc()
        .format();
    },
    setActivePane(evt) {
      for (let pane in this.activePane) {
        this.activePane[pane] = pane === evt.pane;
      }
    },
    resetDefaultPanes() {
      this.activePane = this.defaultPanes();
    }
  },
  watch: {
    curDate: "resetDefaultPanes"
  }
};
</script>

<style scoped>
.event-entry {
  display: flex;
  flex-flow: row nowrap;
}

.event-card {
  margin: 15px 20px 10px 20px;
  width: 100%;
}

.tracker {
  margin-top: 35px;
}
.toggle-past-events {
  font-size: 11px;
}
.no-more-text {
  margin-left: 15px;
}
</style>
