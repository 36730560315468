<template>
  <div class="box right-side" v-if="pendingCount > 0">
    <div v-if="pendingCount > 0">
      View or update
      <router-link :to="{ name: 'ViewPending' }">
        {{ pendingCount }} event<span v-if="pendingCount > 1">s</span>
      </router-link>
      pending approval
    </div>
  </div>
</template>

<script>
import db from "@/firebase/firestore.js";
import errors from "@/models/errors";
import vweUser from "@/models/user";

export default {
  name: "UserToolBox",
  data() {
    return {
      pendingCount: 0
    };
  },
  computed: {
    userId() {
      if (!vweUser || !vweUser.user) {
        return null;
      }
      if (vweUser.user.id == undefined) {
        return null;
      }
      return vweUser.user.id;
    }
  },
  created() {
    if (!this.userId) {
      return;
    }
    try {
      close = db
        .collection("pending_events")
        .where("submittedBy.id", "==", vweUser.user.id)
        .onSnapshot(ss => {
          this.pendingCount = ss.size;
        });
      this.$store.dispatch("addCleanup", close);
    } catch (err) {
      errors.logException(err);
      errors.logError(`Query source user was ${JSON.stringify(vweUser.user)}`);
    }
  }
};
</script>

<style scoped>
.right-side {
  margin-right: 20px;
}
</style>
