<template>
  <div>
    <EventEditor
      v-if="!notFound"
      :start-date="this.date"
      :event-id="id"
      :event-table="eventTable"
      :past-event="pastEvent"
      @not-found="notFound = true"
    ></EventEditor>
    <div class="notification is-danger" v-if="notFound">
      That event could not be found. Please check your link and try again.
    </div>
  </div>
</template>

<script>
import analytics from "../../models/analytics";
import EventEditor from "@/components/display/EventEditor";

export default {
  name: "EditEvent",
  props: {
    date: String,
    id: String,
    "event-table": {
      type: String,
      default: "events"
    }
  },
  data() {
    return {
      notFound: false
    };
  },
  components: { EventEditor },
  mounted() {
    analytics.screenView();
  }
};
</script>

<style scoped></style>
