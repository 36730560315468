<template>
  <div class="footer">
    <div class="footer-text footer-intro">
      Copyright &copy; 2020 VirtualWineEvents
    </div>
    <div class="columns">
      <div class="column is-one-third">
        <div>
          <span class="footer-text">
            <router-link :to="{ name: 'FAQ' }">
              Frequently Asked Questions
            </router-link>
          </span>
        </div>
        <div>
          <span class="footer-text">
            <router-link :to="{ name: 'PrivacyPolicy' }">
              Privacy Policy
            </router-link>
          </span>
        </div>
      </div>
      <div class="column is-one-third">
        <span class="footer-text">
          <router-link :to="{ name: 'AboutUs' }">
            About Virtual Wine Events
          </router-link>
        </span>
      </div>
      <div class="column is-one-third">
        <span class="footer-text">
          <a
            href="mailto:events@spitbucket.net?subject='Virtual Wine Events Feedback'"
          >
            Contact Us
          </a>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppFooter"
};
</script>

<style scoped>
.footer-text {
  color: #b0b0b0;
  font-size: 14px;
}

.footer-intro {
  margin-bottom: 18px;
}

.footer-text a:link {
  color: #b0b0b0;
}
.footer-text a:visited {
  color: #b0b0b0;
}
.footer-text a:hover {
  color: #404040;
}
</style>
