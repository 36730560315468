<template>
  <div class="modal" :class="{ 'is-active': showImage }">
    <div class="modal-background" @click="$emit('close')"></div>
    <div class="modal-content faq-image" @click="$emit('close')">
      <p class="image">
        <img class="displayed-image" :src="src" />
      </p>
    </div>
    <button
      class="modal-close is-large"
      aria-label="close"
      @click="$emit('close')"
    ></button>
  </div>
</template>

<script>
export default {
  name: "ImagePopup",
  props: {
    "show-image": {
      required: true,
      type: Boolean
    },
    src: {
      required: true
    }
  }
};
</script>

<style scoped>
@media screen and (min-width: 769px) {
  .modal-content.faq-image {
    width: 80%;
    max-height: 80%;
  }
}

.displayed-image {
  max-height: 100%;
  width: auto;
  margin: auto;
}
</style>
