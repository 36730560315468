<template>
  <div>
    <div>
      <ul class="star-list">
        <li
          v-for="evt in upcomingStarredEvents"
          :key="evt.id"
          class="starred-detail"
          v-if="getFullEventName(evt.id) != ''"
        >
          {{ getEventDate(evt.id) }}
          <router-link :to="{ name: 'ViewEvent', params: { id: evt.id } }">
            {{ getFullEventName(evt.id) }}
          </router-link>
        </li>
      </ul>
    </div>
    <div
      v-if="user && upcomingStarredEvents.length == 0"
      class="panel-block no-upcoming-events"
    >
      You have no upcoming starred events.
    </div>
    <div v-if="user && hasBookmarks()">
      <router-link class="view-all" :to="{ name: 'ViewBookmarks' }"
        >View all my bookmarks</router-link
      >
    </div>
    <div v-if="!user" class="panel-block no-upcoming-events">
      Please log in to track and star upcoming events.
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
import flags from "../../models/flags";
import { mapGetters } from "vuex";

export default {
  name: "BookMarkList",
  data() {
    return {
      firstLoad: Date.now(),
      nonEmpty: false,
      active: flags.itActive
    };
  },
  computed: {
    ...mapGetters([
      "event",
      "user",
      "starredEventList",
      "upcomingStarredEvents"
    ])
  },
  methods: {
    getFullEventName(id) {
      let fullEvent = this.event(id);
      if (fullEvent) {
        this.nonEmpty = true;
        return fullEvent.name;
      }
      this.$store.dispatch("loadEvent", { id: id });
      return "";
    },
    getEventDate(id) {
      let fullEvent = this.event(id);
      if (fullEvent) {
        return `${moment(fullEvent.date).format("llll")}:`;
      }
      this.$store.dispatch("loadEvent", { id: id });
      return "";
    },
    hasBookmarks() {
      return this.starredEventList.length > 0;
    }
  }
};
</script>

<style scoped>
.starred-detail {
  font-size: 0.825em;
}
.star-list {
  list-style-type: circle;
  margin-left: 8px;
}
.no-upcoming-events {
  padding-top: 10px;
}
.view-all {
  margin-left: 1em;
  font-size: 0.75em;
}
</style>
