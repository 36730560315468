<template>
  <a
    :href="socialLink(handle)"
    target="_blank"
    class="social-link"
    @click="gotoEvent(`visit_user_${network}`)"
  >
    <div
      v-if="
        typeof this.handle != 'undefined' &&
          this.handle &&
          this.handle.length > 1
      "
      class="social-account"
    >
      <i :class="socialIcon(network)"></i>
      {{ socialAccount(handle) }}
    </div>
  </a>
</template>

<script>
import vweUser from "../../models/user";
import analytics from "../../models/analytics";
import errors from "../../models/errors";
import moment from "moment-timezone";
import firebase from "firebase/app";

export default {
  name: "SocialLink",
  props: {
    network: {
      type: String,
      required: true
    },
    handle: { type: String, required: true },
    "event-id": { type: String, default: null }
  },
  methods: {
    domainFor(network) {
      switch (network) {
        case "twitter":
          return "twitter.com/";
        case "instagram":
          return "instagram.com/";
        default:
          return "bad-domain.xyz/";
      }
    },
    socialIcon(network) {
      switch (network) {
        case "twitter":
          return "fab fa-twitter-square twitter-blue";
        case "instagram":
          return "fab fa-instagram-square ig-back";
      }
    },
    socialLink(handle) {
      if (handle.includes(this.domainFor(this.network))) {
        return handle;
      }
      return `https://${this.domainFor(this.network)}${this.linkifyHandle(
        handle
      )}`;
    },
    socialAccount(h) {
      var handle = h;
      if (!handle) {
        return "";
      }
      if (handle[0] == "@") {
        handle = handle.substring(1);
      }
      if (!handle.includes(this.domainFor(this.network))) {
        return handle;
      }
      if (handle.endsWith("/")) {
        handle = handle.substring(0, handle.length - 1);
      }
      let parts = handle.split("/");
      if (parts.length > 1) {
        return parts[parts.length - 1];
      }
      return "";
    },
    gotoEvent(eventType) {
      if (vweUser.isAdmin()) {
        return;
      }
      // conditionally log the event.
      analytics.logEvent(eventType);
      // Make the call to the backend so we can register the interest.
      let viewDetailEvent = firebase.functions().httpsCallable("onViewEvent");
      if (this.eventId) {
        viewDetailEvent({
          id: vweUser.getInterestPersona(),
          eventId: this.eventId
        }).catch(err => {
          console.error("Error calling function ", err);
          errors.logException(err);
        });
      }
    },
    linkifyHandle(handle) {
      if (!handle) {
        return "";
      }
      let trimmedHandle = handle.trim();
      if (trimmedHandle.startsWith("@")) {
        return trimmedHandle.substring(1);
      }
      return trimmedHandle;
    }
  }
};
</script>

<style scoped>
.twitter-blue {
  color: #00acee;
}

.ig-back {
  color: #e1306c;
}

.social-link {
}
</style>
