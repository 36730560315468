<template>
  <div>
    <div class="event-view">
      <InterestTracker
        class="tracker"
        :event="event"
        v-if="isActive() && event"
        :disabled="now() > event.date"
      ></InterestTracker>
      <EventCard
        class="event-card"
        :event="event"
        v-if="event"
        date_format="LLL"
        :no_truncate="true"
      >
      </EventCard>
    </div>
    <div class="submitter box" v-if="user.isAdmin() && submittedBy">
      <div class="has-text-centered has-background-primary header-admin">
        <span class="has-text-white-bis">[Admin Only]</span>
      </div>
      <span class="submitter-data"> Event Submitted by: </span>
      <div class="media submitter-data">
        <div class="media-left" v-if="submittedBy.avatar_url">
          <figure class="image is-32x32">
            <img :src="submittedBy.avatar_url" referrerpolicy="no-referrer" />
          </figure>
        </div>
        <span class="submitter-data media-content">
          {{ submittedBy.name }} <br />
          ({{ submittedBy.email }})
        </span>
        <div class="submitter-data">
          <span>Buzz Score: {{ event.buzz || 0 }}</span>
        </div>
      </div>
    </div>
    <div v-if="notFound" class="notification is-danger">
      The event could not be found. Please check the id on your link, and try
      again.
    </div>
    <div>
      <Spinner class="is-centered" v-if="!event && !notFound"></Spinner>
    </div>
  </div>
</template>

<script>
import analytics from "@/models/analytics";
import db from "@/firebase/firestore";
import errors from "@/models/errors";
import flags from "../../models/flags";
import firebase from "firebase/app";
import moment from "moment-timezone";
import vweUser from "@/models/user";

import Spinner from "../display/Spinner";
import EventCard from "@/components/display/EventCard";
import InterestTracker from "../display/InterestTracker";

export default {
  name: "EventDetail",
  components: { EventCard, InterestTracker, Spinner },
  data() {
    return {
      event: null,
      submitter: null,
      loadingSubmitter: false,
      notFound: false
    };
  },
  computed: {
    eventId() {
      return this.$route.params.id;
    },
    user() {
      return vweUser;
    },
    submittedBy() {
      if (this.submitter) {
        return this.submitter;
      }
      if (this.event && !this.loadingSubmitter) {
        this.loadingSubmitter = true;
        (async ctx => {
          let submitterInfo = await db
            .collection("users")
            .doc(ctx.event.submittedBy.id)
            .get();
          ctx.submitter = submitterInfo.data();
        })(this);
        return this.submitter;
      }
      return null;
    }
  },
  methods: {
    updateRoute() {
      this.notFound = false;
      this.loadEvent();
    },
    now() {
      return moment()
        .utc()
        .format();
    },
    cacheEvent(e) {
      this.$store.dispatch("storeEvent", e);
    },
    loadEvent() {
      if (this.event && this.event.id == this.eventId) {
        return;
      }
      let cachedEvent = this.getEvent();
      if (cachedEvent) {
        this.event = cachedEvent;
      }
      db.collection("events")
        .doc(this.eventId)
        .get()
        .then(item => {
          if (!item.data()) {
            console.error("Error retrieving event. - data was nil");
            this.notFound = true;
            return;
          }
          this.notFound = false;
          let newEvent = item.data();
          newEvent.id = item.id;
          this.cacheEvent(newEvent);
          this.event = newEvent;
          let eventDate = moment(newEvent.date).format("LL");
          let pageTitle = `Virtual Wine Events: ${newEvent.name} on ${eventDate} `;
          document.title = pageTitle;

          // If the user is an admin, we don't want to record anything
          if (vweUser.isAdmin()) {
            return;
          }
          // send details if they've agreed to cookies.
          logGAEvent(this);
          // Make the call to the backend so we can compute the interest.
          logBuzzForEvent(this);
        })
        .catch(e => {
          this.notFound = true;
          errors.logException(e);
          console.error(e);
        });
    },
    getEvent() {
      if (!this.eventId) {
        return null;
      }
      let event = this.$store.getters.event(this.eventId);
      return event;
    },
    isActive() {
      return flags.itActive;
    }
  },
  mounted() {
    analytics.screenView();
    this.loadEvent();
  },
  watch: {
    $route: "updateRoute"
  }
};

// Send event information to Google analytics.
function logGAEvent(t) {
  analytics.logEvent("event_detail_viewed", {
    tags: t.event.categories,
    hour: moment(t.event.date).format("HH")
  });
  t.event.categories.forEach(cat => analytics.logEvent(`event_detail_${cat}`));
  analytics.logEvent(`event_detail_hour_${moment(t.event.date).format("HH")}`);
}

// Make the call to the function that will register interest in the event.
function logBuzzForEvent(t) {
  let viewDetailEvent = firebase.functions().httpsCallable("onViewDetail");
  viewDetailEvent({
    id: vweUser.getInterestPersona(),
    eventId: t.event.id
  }).catch(err => {
    console.error("Error calling function ", err);
  });
}
</script>

<style scoped>
.event-card {
  padding: 7px;
}
.tracker {
  margin-top: 35px;
}
.event-view {
  display: flex;
  flex-flow: row nowrap;
}
.header-admin {
  margin-left: -22px;
  margin-right: -22px;
  margin-top: -22px;
}
.submitter {
  background-color: rgb(253, 251, 251);
  font-size: 0.75rem;
  margin-top: 15px;
  margin-left: 45px;
}
</style>
