<template>
  <div>
    <div class="search-header">
      <div class="subtitle is-5">
        <span v-if="standardSearch">Search</span>
        <span v-else> Video search </span> results for "<i>{{
          displayedQuery
        }}</i
        >" <span id="algolia-text">powered by</span>
        <img class="algolia ml-1" src="@/assets/logo-algolia.png" />
      </div>
    </div>
    <div class="search-header" v-if="results.length > 0">
      <h2 class="subtitle is-6" v-if="standardSearch">
        Upcoming events:
      </h2>
      <ul>
        <li v-for="event in results" :key="event.objectID">
          <EventListItem :event="event"></EventListItem>
        </li>
      </ul>
    </div>
    <div class="search-header" v-if="pastResults.length > 0">
      <h2 class="subtitle is-6" v-if="standardSearch">
        Past events:
      </h2>
      <ul>
        <li v-for="event in pastResults" :key="event.objectID">
          <EventListItem :event="event"></EventListItem>
        </li>
      </ul>
    </div>
    <Paginator :pages="pages" @newPageNumber="updatePage"></Paginator>
    <div
      class="search-header"
      v-if="pastResults.length == 0 && results.length == 0 && !searchPending"
    >
      <h2 class="subtitle is-6">
        There are no matching events.
      </h2>
    </div>
    <Spinner v-if="searchPending"> </Spinner>
  </div>
</template>

<script>
import algolia from "algoliasearch";
import moment from "moment-timezone";

import { EventBus } from "../../models/event-bus";
import analytics from "../../models/analytics";
import EventListItem from "../../components/display/EventListItem";
import Spinner from "../../components/display/Spinner";
import Paginator from "../widgets/Paginator";

const ALGOLIA_APP_ID = "0QEEI7IP8V";
const ALGOLIA_SEARCH_KEY = "d5e8bf1f89c1bd125959ba5cd4014144";
let searchClient = algolia(ALGOLIA_APP_ID, ALGOLIA_SEARCH_KEY);
let index = searchClient.initIndex("prod_events");

export default {
  name: "SearchResults",
  props: ["query"],
  components: { Paginator, EventListItem, Spinner },
  data() {
    return {
      displayedQuery: this.query,
      results: [],
      pastResults: [],
      searchPending: false,
      standardSearch: !this.$route.params.videos,
      pages: 0,
      curPage: this.$route.query.page ? this.$route.query.page : 1,
      algoliaObj: null,
      queryObj: null
    };
  },
  mounted() {
    search(this.query, this, this.page);
    EventBus.on("new-search", query => {
      this.standardSearch = !query.videos;
      search(query.query, this, 1);
    });
    analytics.screenView();
  },
  methods: {
    updatePage(newPage) {
      search(this.displayedQuery, this, newPage);
    }
  }
};

function processResults(target) {
  return resp => {
    target.algoliaObj = resp;
    target.pages = resp.nbPages;
    target.searchPending = false;
    let now = moment();
    resp.hits.forEach(resp => (resp.id = resp.objectID));

    let results = resp.hits;
    target.results = results.filter(e => moment(e.date) > now);
    target.pastResults = results.filter(e => moment(e.date) <= now);
  };
}
function search(term, target, page) {
  target.displayedQuery = term;
  target.searchPending = true;
  target.results = [];
  target.pastResults = [];
  if (!page) {
    page = 1;
  }
  let searchParams = {
    hitsPerPage: 12,
    page: page - 1
  };
  if (!target.standardSearch) {
    searchParams.filters = "has-video";
  }
  index.search(term, searchParams).then(processResults(target));
}
</script>

<style scoped>
.search-header {
  margin: 18px 22px;
}

.center {
  margin: auto;
}

.nudge-down {
  margin-top: 30px;
}
#algolia-text {
  font-size: 12px;
}

.algolia {
  max-height: 16px;
  vertical-align: middle;
}
</style>
