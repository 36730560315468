// Vuex store for trending details

import Vue from "vue";
import db from "../../firebase/firestore";
import moment from "moment-timezone";

let trendingReplayStore = {
  state: {
    trendingReplays: []
  },
  mutations: {
    updateTrendingReplayList(state, { replays }) {
      Vue.set(state, "trendingReplays", replays);
    }
  },
  actions: {
    refreshTrendingReplays(context) {
      db.collection("trending_videos")
        .doc("current")
        .get()
        .then(doc => {
          context.commit("updateTrendingReplayList", {
            replays: doc.data().videos
          });
        });
    },
    loadTrendingReplays(context) {
      if (context.state.trendingReplays.length > 0) {
        return;
      }
      context.dispatch("refreshTrendingReplays");
      setInterval(() => {
        context.dispatch("refreshTrendingReplays");
      }, 1800 * 1000);
    }
  },
  getters: {
    trendingReplays: state => {
      return state.trendingReplays;
    }
  }
};

export default trendingReplayStore;
