<template>
  <div class="date-nav">
    <div id="event-header">
      <!-- Notification messages -->
      <div
        class="notification is-primary is-small is-light"
        v-if="renderNotification"
      >
        <button
          class="delete is-small"
          @click="renderNotification = false"
        ></button>
        {{ formatMessage(message) }}
      </div>
      <div id="date-selector">
        <DateSelector :start_date="curDate" @new-date="changeDate" />
      </div>
      <div id="add-event" v-if="user() != null">
        <button class="button is-primary" @click="addEvent">
          <span class="icon is-small">
            <i class="fa fa-calendar-edit"></i>
          </span>
          <span>Add Event</span>
        </button>
      </div>
      <div id="add-event-disabled" v-if="user() === null">
        <button class="button is-primary" disabled @click="addEvent">
          <span class="icon is-small">
            <i class="fa fa-calendar-edit"></i>
          </span>
          <span>Login to Add Event</span>
        </button>
      </div>
      <div class="clear"></div>
    </div>
    <DesktopEventViewer
      class="is-hidden-touch"
      :upcoming-events="upcomingEventsForDay(curDate)"
      :past-events="pastEventsForToday()"
      :cur-date="curDate"
      @deleted="deleteEvent"
    ></DesktopEventViewer>
    <MobileEventViewer
      class="is-hidden-desktop"
      :upcoming-events="upcomingEventsForDay(curDate)"
      :past-events="pastEventsForToday()"
      :cur-date="curDate"
      @deleted="deleteEvent"
    ></MobileEventViewer>
    <Spinner v-if="loading && allEventsForDay(curDate).length == 0"> </Spinner>
    <div
      v-if="
        !loading &&
          upcomingEventsForDay(curDate).length == 0 &&
          !(showingToday(curDate) && pastEventsForToday().length > 0)
      "
    >
      <p class="subtitle is-5 no-events nudge-right">
        There are no events for this day.
      </p>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
import analytics from "../../models/analytics";
import db from "@/firebase/firestore";
import errors from "@/models/errors";
import DateSelector from "@/components/display/DateSelector";
import DesktopEventViewer from "@/components/layouts/DesktopEventViewer";
import vweUser from "@/models/user";
import MobileEventViewer from "@/components/layouts/MobileEventViewer";
import Spinner from "@/components/display/Spinner";

// Figures out the starting date.  If the date isn't valid, then we use today.
function startDate(year, month, day) {
  var m = moment([year, parseInt(month) - 1, day]);
  if (m.isValid()) {
    return m;
  }
  return moment(moment().format("YYYY-MM-DD"));
}

export default {
  name: "EventViewer",
  components: {
    MobileEventViewer,
    DesktopEventViewer,
    DateSelector,
    Spinner
  },
  props: ["msg"],
  data() {
    return {
      curDate: startDate(
        this.$route.params.year,
        this.$route.params.month,
        this.$route.params.day
      ),
      events: [],
      loading: true,
      showInterestTracker: false,
      message: this.msg,
      renderNotification: this.msg != undefined
    };
  },
  methods: {
    // update the displayed date if the route changes on us.
    updateRoute() {
      this.curDate = startDate(
        this.$route.params.year,
        this.$route.params.month,
        this.$route.params.day
      );
      this.loadEvents(
        () => (this.loading = true),
        () => (this.loading = false)
      );
      this.updatePageTitle();
      analytics.screenView();
    },
    updatePageTitle() {
      document.title = `Virtual Wine Events: Events on ${moment(
        this.curDate
      ).format("LL")}`;
    },
    allEventsForDay(day) {
      return this.events.filter(
        event => moment(event.date).format("LL") == day
      );
    },
    upcomingEventsForDay(day) {
      let now = moment().subtract(10, "minutes");
      return this.events.filter(event => {
        let eventMoment = moment(event.date);
        let eventDay = eventMoment.format("LL");
        if (eventDay != moment(day).format("LL")) {
          return false;
        }
        if (now.format("LL") != eventDay) {
          return true;
        }
        return eventMoment > now;
      });
    },
    pastEventsForToday() {
      let now = moment().subtract(10, "minutes");
      let today = now.format("LL");
      return this.events.filter(event => {
        let eventMoment = moment(event.date);
        let eventDay = eventMoment.format("LL");
        if (today != eventDay) {
          return false;
        }
        return eventMoment < now;
      });
    },
    changeDate(newDate) {
      this.curDate = newDate;
      this.$router.push({
        name: "EventsFor",
        params: {
          year: newDate.year(),
          month: newDate.month() + 1,
          day: newDate.date()
        }
      });
    },
    addEvent(e) {
      this.$router.push({
        name: "AddEvent",
        params: { date: this.curDate }
      });
    },
    formatMessage(msg) {
      switch (msg) {
        case "saved":
          return "Your event has been saved!";
        case "changed":
          return "Your changes to the event has been saved.  They will be reviewed and then activated by an administrator.";
        case "pending":
          return "Your event has been saved.  It will be reviewed and then added by the admins.";
        case "updated":
          return "Your profile has been updated. Thank you!";
        case "update-event":
          return "Your event has been updated.";
        default:
          return msg;
      }
    },
    user() {
      return vweUser.user;
    },
    showingToday(date) {
      return moment(date).format("LL") == moment().format("LL");
    },
    deleteEvent(event) {
      this.events = this.events.filter(e => e.id != event.id);
    },
    storeDayEvents(day, events) {
      this.$store.dispatch("storeDayEvents", { day, events });
    },
    loadEvents(start, done) {
      let windowStart = moment(this.curDate).add(0, "days");
      let windowEnd = moment(this.curDate).add(1, "days");
      if (start) {
        start();
      }
      let thisDay = this.curDate.format("YYYY-MM-DD");
      let cached = this.$store.getters.eventsForDay(thisDay);

      if (cached && cached.length > 0) {
        this.events = cached;
        done();
        return;
      }

      db.collection("events")
        .where("date", ">=", windowStart.utc().format())
        .where("date", "<", windowEnd.utc().format())
        .orderBy("date")
        .get()
        .then(snapshot => {
          let events = [];
          snapshot.forEach(doc => {
            let event = doc.data();
            event.id = doc.id;
            events.push(event);
          });

          this.storeDayEvents(thisDay, events);
          this.events = events;
          if (done) {
            done();
          }
        })
        .catch(err => {
          errors.logException(err);
          console.error(err);
          done();
        });
    }
  },
  watch: {
    $route: "updateRoute"
  },
  metaInfo() {
    let metaObj = {
      link: []
    };
    if (this.$route.name == "Events") {
      metaObj.link.push({
        rel: "canonical",
        href: "https://virtualwineevents.com/events"
      });
    }
    return metaObj;
  },
  // Use the right way to format and display dates to the user.
  beforeCreate() {
    moment.locale(window.navigator.language);
  },
  created() {
    if (this.$route.name == "EventsFor") {
      this.updatePageTitle();
    }
  },
  mounted() {
    // get the data from firestore
    this.loadEvents(
      () => (this.loading = true),
      () => (this.loading = false)
    );
    analytics.screenView();
  }
};
</script>

<style scoped>
.date-nav {
  padding: 10px 15px;
}

#date-selector {
  padding-left: 20px;
  padding-top: 4px;
  float: left;
}

#add-event {
  padding-right: 30px;
  padding-top: 2px;
  float: right;
}

#add-event-disabled {
  padding-right: 30px;
  padding-top: 2px;
  float: right;
}

.clear {
  clear: both;
}

.center {
  margin: auto;
}

.nudge-down {
  padding-top: 75px;
}
.nudge-right {
  padding-left: 25px;
}
</style>
