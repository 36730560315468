<template>
  <div :class="{ 'is-active': isVisible }" class="modal">
    <div class="modal-background"></div>

    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ errorTitle }}</p>
        <button class="delete" aria-label="close" @click="close"></button>
      </header>

      <section class="modal-card-body">
        <article class="message is-danger">
          <div class="message-body">
            {{ errorMessage }}
          </div>
        </article>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-danger" @click="close">Close</button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: "ErrorModal",
  props: ["is-visible", "error-title", "error-message"],
  data() {
    return {};
  },
  methods: {
    close() {
      this.$emit("close");
    }
  }
};
</script>

<style scoped></style>
