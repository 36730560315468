// Routes related to admin users
import utils from "./utils";

import EventApproval from "@/components/admin/EventApproval";
import EventDashboard from "../components/admin/EventDashboard";
import EditEvent from "@/components/layouts/EditEvent";
import UpdateAprroval from "../components/admin/UpdateAprroval";

export default [
  {
    path: "/admin-tasks/events/approve",
    name: "ApproveEvents",
    component: EventApproval,
    beforeEnter: utils.checkAdmin
  },
  {
    path: "/event/:id/edit",
    name: "EditEvent",
    component: EditEvent,
    beforeEnter: utils.checkAdmin,
    props: true
  },
  {
    path: "/admin-tasks/event-changes/approve",
    name: "ApproveChanges",
    component: UpdateAprroval,
    beforeEnter: utils.checkAdmin
  },
  {
    path: "/admin-tasks/dashboards/events",
    name: "EventDashboard",
    component: EventDashboard,
    beforeEnter: utils.checkAdmin
  }
];
