<template>
  <div class="interest-tracker">
    <div
      class="tooltip"
      :class="{ 'wine-colored': !voted, 'brighter-wine-colored': voted }"
    >
      <span class="icon is-large" @click="registerInterest">
        <i v-if="voted" class="fas fa-2x fa-star"> </i>
        <i v-if="!voted" class="far fa-2x fa-star"> </i>
      </span>
      <span class="tooltiptext" v-if="!disabled && !user.loggedIn()"
        >Log in to bookmark.</span
      >
      <span class="tooltiptext" v-if="!disabled && user.loggedIn()"
        >Click to bookmark.</span
      >
      <span class="tooltiptext" v-if="disabled"
        >This event has passed and cannot be bookmarked.</span
      >
    </div>
  </div>
</template>

<script>
import analytics from "../../models/analytics";
import errors from "../../models/errors";

import vweUser from "@/models/user";
import firebase from "@/firebase/firebase";

export default {
  name: "InterestTracker",
  props: {
    logged_in: {
      type: Boolean,
      default: false
    },
    votes: {
      type: Number,
      default: 0
    },
    event: {
      type: Object
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      lastClick: Date.now(),
      user: vweUser
    };
  },
  computed: {
    voted() {
      return this.$store.getters.starredEvent(this.event.id);
    }
  },
  methods: {
    registerInterest() {
      if (this.disabled) {
        return;
      }
      let now = Date.now();
      if (now < this.lastClick + 750) {
        return;
      }
      if (!this.user.loggedIn()) {
        return;
      }
      this.lastClick = now;
      let functionName = this.voted ? "onRemoveInterest" : "onLogInterest";
      // Make the call to the backend so we can register the interest.
      if (this.voted) {
        this.$store.commit("removeInterest", this.event.id);
      } else {
        this.$store.commit("addInterest", {
          id: this.event.id,
          data: { eventDate: this.event.date }
        });
        analytics.logEvent("event_starred");
      }
      let bookmarkFn = firebase.functions().httpsCallable(functionName);
      let persona = vweUser.getInterestPersona();
      if (persona) {
        bookmarkFn({
          id: persona,
          eventId: this.event.id,
          eventDate: this.event.date
        }).catch(err => {
          console.errpr("Error calling function ", err);
          errors.logException(err);
        });
      } else {
        errors.logError(
          "User logged in, but persona appeared to be null while bookmarking."
        );
      }
    }
  }
};
</script>

<style>
.wine-colored {
  color: #722f37;
}
.brighter-wine-colored {
  color: #894e51;
}
.it-bg-non-selected {
  background-color: #dadada;
}

.it-bg-selected {
  background-color: #eef3ee;
}

.interested-text {
  font-family: "DM Sans", Arial, Helvetica, sans-serif;
  font-size: 10px;
  font-weight: 400;
  margin-left: 0px;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  top: -5px;
  left: 105%;
  position: absolute;
  z-index: 1;
}
.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>
