// selection of routes related to manipulation and display of events

import utils from "./utils";
import moment from "moment";

import AddEvent from "@/components/layouts/AddEvent";
import EventDetail from "@/components/layouts/EventDetail";
import EditEvent from "@/components/layouts/EditEvent";
import EventViewer from "@/components/layouts/EventViewer";
import ListCategory from "@/components/layouts/EventList";
import SearchResults from "@/components/layouts/SearchResults";
import UserUpdate from "../components/layouts/UserUpdate";
import ViewBookmarks from "../components/layouts/ViewBookmarks";
import ViewPendingEvents from "@/components/layouts/ViewPendingEvents";

export default [
  {
    path: "/events/type/:category",
    name: "ListCategory",
    component: ListCategory
  },
  {
    path: "/event/:id/update-video",
    name: "UserUpdateEvent",
    component: UserUpdate
  },
  {
    path: "/event/:id",
    name: "ViewEvent",
    component: EventDetail,
    meta: {
      hasDesc: true
    }
  },
  // update events
  {
    path: "/events/add",
    name: "AddEvent",
    component: AddEvent,
    beforeEnter: utils.checkLoggedIn,
    props: true,
    meta: {
      title: "Virtual Wine Events: Add a New Event"
    }
  },
  // add a past events
  {
    path: "/past-event/add",
    name: "AddPastEvent",
    component: AddEvent,
    beforeEnter: utils.checkLoggedIn,
    props: { "past-event": true, date: moment().subtract(1, "days") },
    meta: {
      title: "Virtual Wine Events: Add a Past Event"
    }
  },
  // search for events
  {
    path: "/events/search",
    name: "SearchEvents",
    component: SearchResults,
    props: true
  },
  {
    path: "/events/pending/:id/edit",
    name: "EditPending",
    component: EditEvent,
    props: route => ({ id: route.params.id, "event-table": "pending_events" })
  },
  {
    path: "/events/pending",
    name: "ViewPending",
    component: ViewPendingEvents
  },
  // More basic event routes
  // display events
  {
    path: "/events/:year/:month/:day",
    name: "EventsFor",
    component: EventViewer
  },
  {
    path: "/events",
    name: "Events",
    component: EventViewer,
    props: true
  },
  {
    path: "/bookmarks",
    name: "ViewBookmarks",
    beforeEnter: utils.checkLoggedIn,
    component: ViewBookmarks
  },
  {
    path: "/",
    redirect: "/events"
  }
];
