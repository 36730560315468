<template>
  <div>
    <figure class="image is-48x48 center">
      <img src="/spin.gif" />
    </figure>
  </div>
</template>

<script>
export default {
  name: "Spinner"
};
</script>

<style scoped>
.center {
  margin: auto;
  padding-top: 25px;
}
</style>
