<template>
  <div class="panel has-margin is-primary" v-if="active">
    <div class="panel-heading">
      Trending Events and Replays
    </div>
    <p class="panel-tabs">
      <a
        :class="{ 'is-active': shownTab === 'events' }"
        @click="activeTab = 'events'"
        >Events</a
      >
      <a
        :class="{ 'is-active': shownTab === 'replays' }"
        @click="activeTab = 'replays'"
        >Replays</a
      >
      <a
        v-if="user"
        :class="{ 'is-active': shownTab === 'bookmarks' }"
        @click="activeTab = 'bookmarks'"
        >Bookmarks</a
      >
    </p>
    <div class="panel-block" v-if="user && activeTab === 'bookmarks'">
      <BookMarkList></BookMarkList>
    </div>
    <div class="panel-block" v-if="activeTab === 'replays'">
      <TrendingReplayList></TrendingReplayList>
    </div>
    <div class="panel-block" v-if="activeTab === 'events'">
      <TrendingEventList page="3"></TrendingEventList>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import flags from "../../models/flags";

import moment from "moment-timezone";
import BookMarkList from "../display/BookMarkList";
import TrendingEventList from "../display/TrendingEventList";
import TrendingReplayList from "../display/TrendingReplayList";

export default {
  name: "TrendingPanel",
  components: { TrendingEventList, TrendingReplayList, BookMarkList },
  data() {
    return { active: flags.itActive, activeTab: "events" };
  },
  computed: {
    shownTab() {
      if (!this.user) {
        return "events";
      }
      return this.activeTab;
    },
    ...mapGetters(["user"])
  }
};
</script>

<style scoped>
.has-margin {
  margin-right: 25px;
}
</style>
