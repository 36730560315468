<template>
  <div class="card">
    <div class="card-content">
      <div class="event-title">
        {{ event.name }}
      </div>

      <div class="columns">
        <div class="column">
          <div
            class="event-cost"
            :class="{ 'event-free': event.cost === '0' }"
            v-if="!videoHasFee"
          >
            <p>{{ fee === "0" ? "Free" : event.cost }}</p>
          </div>
          <div class="field has-addons" v-else>
            <div class="control">
              <span class="select">
                <select v-model="currency">
                  <option>$</option>
                  <option>£</option>
                  <option>€</option>
                </select>
              </span>
            </div>
            <div class="control">
              <input class="input" type="text" v-model="fee" />
            </div>
          </div>
          <div class="event-time">
            <p>{{ formatEventDate(event.date) }}</p>
          </div>
          <div
            class="video-update my-3"
            v-if="editableFields.includes('video-url')"
          >
            <div class="field">
              <label class="label">Video URL</label>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <input
                      class="input"
                      :class="{ 'is-danger': invalidUrl }"
                      type="text"
                      placeholder="http://"
                      v-model="videoUrl"
                    />
                  </div>
                  <p class="help is-danger" v-if="invalidUrl">
                    Please enter a valid URL
                  </p>
                </div>
              </div>
              <label class="checkbox mt-2">
                This video requires purchase:
                <input type="checkbox" class="ml-1" v-model="videoHasFee" />
                <p class="is-info help" v-if="videoHasFee">
                  Please update the cost above if needed.
                </p>
              </label>
            </div>
          </div>
          <div class="media">
            <div class="media-content" v-if="event.host.hasOwnProperty('name')">
              <p class="title is-5">Hosted by: {{ event.host.name }}</p>
              <div class="subtitle is-6">
                <SocialLink
                  network="twitter"
                  :handle="event.host.contact_info.twitter"
                  :event-id="event.id"
                ></SocialLink>
                <SocialLink
                  network="instagram"
                  :handle="event.host.contact_info.instagram"
                  :event-id="event.id"
                ></SocialLink>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content">
        <div>
          <span class="event-desc"> {{ event.description }} </span>
        </div>
      </div>
      <div class="card-footer event-card-footer">
        <div class="tags event-tags card-footer-item">
          <span
            v-for="(category, index) in event.categories"
            :key="index"
            class="tag"
            :class="formatTag(category)"
          >
            {{ category }}
          </span>
        </div>
        <div>
          <button
            class="button is-success my-2"
            :disabled="!canSubmit"
            @click="submitEdit"
          >
            Update Event
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";

import db from "../../firebase/firestore";

import vweUser from "../../models/user";
import errors from "../../models/errors";

import SocialLink from "../widgets/SocialLink";

export default {
  name: "CompactEditor",
  props: {
    event: Object,
    date_format: {
      type: String,
      default: "LT"
    },
    "editable-fields": {
      type: Array,
      default: () => []
    }
  },
  components: { SocialLink },
  data() {
    return {
      user: vweUser,
      showImage: false,
      showConfirmation: false,
      videoUrl: this.event.videoUrl ? this.event.videoUrl : "",
      videoHasFee: this.event.videoHasFee ? true : false, // this is to get rid of undefined issues
      fee: this.extractFee(this.event.fee ? this.event.cost : "$0"),
      currency: this.extractCurrency(this.event.fee ? this.event.cost : "$0")
    };
  },
  computed: {
    eventImage() {
      if (this.event.optimizedImage) {
        return this.event.optimizedImage;
      }
      return this.event.eventImage;
    },
    invalidUrl() {
      let url = this.videoUrl.toLocaleLowerCase().trim();
      if (!url) {
        return false;
      }
      let pattern = /^(?:(?:https?):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
      return !pattern.test(url);
    },
    canSubmit() {
      return !this.invalidUrl;
    }
  },
  methods: {
    extractCurrency(val) {
      return val[0];
    },
    extractFee(val) {
      return val.substring(1);
    },
    formatEventDate(d) {
      return (
        moment(d).format(this.date_format) +
        " " +
        moment.tz(moment.tz.guess()).format("zz")
      );
    },
    formatTag(tag) {
      if (tag === "free") {
        return ["is-success"];
      }
      if (tag === "must register") {
        return ["is-danger", "is-light"];
      }
      return ["is-light", "is-info"];
    },
    submitEdit() {
      let eventToWrite = {};
      Object.assign(eventToWrite, this.event);
      delete eventToWrite.id;
      if (this.editableFields.includes("video-url")) {
        eventToWrite.videoUrl = this.videoUrl;
        eventToWrite.videoHasFee = this.videoHasFee;
        if (this.fee !== "0") {
          eventToWrite.cost = this.currency + this.fee;
        } else {
          eventToWrite.cost = "0";
        }
        // store the old video URL for comparing.
        if (this.event.videoUrl) {
          eventToWrite.oldVideoUrl = this.event.videoUrl;
        }
        if (this.videoHasFee && this.event.cost != eventToWrite.cost) {
          eventToWrite.oldCost = this.event.cost;
        }
      }
      db.collection("pending_event_updates")
        .doc(this.event.id)
        .set(eventToWrite)
        .then(ref =>
          this.$router.push({ name: "Events", params: { msg: "changed" } })
        )
        .catch(err => {
          this.showError = true;
          errors.logException(err);
          console.error(err);
        });
    }
  }
};
</script>

<style scoped>
.event-time {
  font-size: 18px;
  font-weight: 500;
  color: #600000;
  padding-left: 20px;
  padding-bottom: 5px;
}
.event-cost {
  font-size: 18px;
  padding-left: 20px;
}
.image-spacer {
  width: 150px;
  height: 150px;
}
.event-image {
  margin: auto;
  max-height: 150px;
  max-width: 150px;
  height: auto;
  width: auto;
}
.event-title {
  font-size: 30px;
  font-weight: bold;
}
.content {
  margin-bottom: 10px;
}
.event-desc {
  white-space: pre-line;
}
.tags.event-tags {
  margin-top: -7px;
}
.push-image {
  margin-top: 20px;
  margin-left: 70px;
}
.card-footer-item:not(:last-child) {
  border-right: 0px;
}
.event-tags.card-footer-item {
  justify-content: left;
  margin-bottom: 7px;
}
.card-footer-item.is-cal-links {
  justify-content: flex-end;
  margin-top: -6px;
}
.card-footer-item {
  padding: 1rem 0.75rem 0px 0px;
}
.card-content {
  padding: 1.5rem 1.5rem 0rem 1.5rem;
}
</style>
