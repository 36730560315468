<template>
  <nav class="pagination is-rounded is-centered" v-if="pageCount > 1">
    <a class="pagination-previous" @click="decrement()"
      ><i class="fas fa-chevron-left"></i
    ></a>
    <a class="pagination-next" @click="increment()">
      <i class="fas fa-chevron-right"></i
    ></a>
    <ul class="pagination-list">
      <li v-for="(item, index) in displayedPages()" :key="index">
        <a
          class="pagination-link "
          :class="{ 'is-current': item == page }"
          v-if="item"
          @click="gotoPage(item)"
          >{{ item }}</a
        >
        <a class="pagination-ellipsis" v-else>&hellip;</a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "Paginator",
  props: {
    items: {
      type: Array,
      default: () => []
    },
    "page-size": {
      type: Number,
      default: 10
    },
    pages: {
      type: Number
    },
    name: {
      type: String,
      default: "items"
    }
  },
  computed: {
    pageCount() {
      if (this.pages) {
        return this.pages;
      }
      return Math.ceil(this.items.length / this.pageSize);
    }
  },
  data() {
    return {
      page: 1
    };
  },
  methods: {
    increment() {
      if (this.page >= this.pageCount) {
        return;
      }
      this.page += 1;
      this.updateParent();
    },
    decrement() {
      if (this.page <= 1) {
        return;
      }
      this.page -= 1;
      this.updateParent();
    },
    gotoPage(page) {
      this.page = page;
      this.updateParent();
    },
    updateParent() {
      if (this.items.length) {
        this.$emit("updateDisplayedItems", this.itemsForPage(this.page));
      }
      if (this.pages) {
        this.$emit("newPageNumber", this.page);
      }
      window.scrollTo(0, 0);
    },
    itemsForPage(page) {
      let first = (page - 1) * this.pageSize;
      let last = page * this.pageSize;
      let slice = this.items.slice(first, last);
      return slice;
    },
    displayedPages() {
      if (
        (this.items.length && this.items.length < this.pageSize) ||
        this.pages == 1
      ) {
        return [];
      }
      var pages = [];
      if (this.pageCount < 9) {
        for (var i = 0; i < this.pageCount; i++) {
          pages.push(i + 1);
        }
      } else {
        pages.push(1);
        pages.push(2);

        if (this.page < 5) {
          pages.push(3);
          pages.push(4);
          pages.push(5);
          pages.push(null);
          pages.push(this.pageCount - 1);
          pages.push(this.pageCount);
          return pages;
        }
        if (this.page >= this.pageCount - 3) {
          pages.push(null);
          pages.push(this.pageCount - 4);
          pages.push(this.pageCount - 3);
          pages.push(this.pageCount - 2);
          pages.push(this.pageCount - 1);
          pages.push(this.pageCount);
          return pages;
        }
        pages.push(null);
        if (this.page != 5) {
          pages.push(this.page - 2);
        }
        pages.push(this.page - 1);
        pages.push(this.page);
        pages.push(this.page + 1);
        pages.push(this.page + 2);
        pages.push(null);
        pages.push(this.pageCount - 1);
        pages.push(this.pageCount);
        return pages;
      }
      return pages;
    },
    forceUpdate() {
      if (this.items.length) {
        this.$emit("updateDisplayedItems", this.itemsForPage(this.page));
      }
    },
    reset() {
      this.page = 1;
    }
  },
  watch: {
    items: "forceUpdate",
    name: "reset"
  },
  created() {
    if (this.items.length) {
      this.$emit("updateDisplayedItems", this.itemsForPage(1));
    }
  }
};
</script>

<style scoped></style>
